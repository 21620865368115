import React from "react";
import PropTypes from 'prop-types';
import { CiSquarePlus } from 'react-icons/ci';
import { MdDeleteForever } from "react-icons/md";

function CorrectForm({ type, title, onchange, data, input, categories, dataName, addField, removeField }) {

    const CategoryStyle = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "wrap",
        width: "70%"
    }

    const PCategoryStyle = {
        fontFamily: "GOTHIC",
        fontSize: "16px",
        display: "flex",
        justifyContent: "center",
        width: "200px",
    }

    const CheckStyle = {
        padding: "10px",
        fontSize: "14px",
        borderRadius: "5px",
        border: "1px solid #ccc",
        margin: "5px",
        marginRight: "10px",
        width: "40px",
    }

    const InputStyle = {
        padding: "10px",
        fontSize: "14px",
        borderRadius: "5px",
        border: "1px solid #ccc",
        margin: "5px",
        marginRight: "10px",
    }

    const PStyle = {
        fontFamily: "GOTHIC",
        fontSize: "16px",
        display: "flex",
        justifyContent: "center",
        width: "150px"
    }

    const WrapperStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        marginBottom: "20px"
    }

    const titleStyle = {
        fontFamily: "GOTHIC",
        fontSize: "x-large",
        fontWeight: "bolder",
        margin: "20px",
    }

    const unitOptions = [
        "",
        "kg",
        "ton",
        "m3",
        "m2",
        "m",
        "stk"
    ];

    const MassefyldeOptions = [
        "",
        "kg",
        "ton",
        "m³",
        "m²",
        "m",
        "stk"
    ];

    const renderContent = () => {
        switch (type) {
            case "Category":
                return (
                    <div style={WrapperStyle}>
                        <label style={titleStyle}>{title}</label>
                        <div style={CategoryStyle}>
                            {categories.map((item) =>
                                <div style={{ display: "flex" }}>
                                    <p style={PCategoryStyle}>{item + ":"}</p>
                                    <input style={CheckStyle}
                                        type="checkbox"
                                        name={title}
                                        checked={data.includes(item) || data === item}
                                        value={item}
                                        onChange={(event) => onchange(event, item)}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )
            case "List":
                return (
                    <div style={WrapperStyle}>
                        <label style={titleStyle}>{title}</label>
                        {data.map((item, index) =>
                            <div div style={{ display: "flex", alignItems: "center" }}>
                                <p style={PStyle}>{index + 1 + ":"}</p>
                                <input
                                    style={InputStyle}
                                    type="text"
                                    name={title}
                                    value={item}
                                    onChange={(event) => onchange(dataName, index, event.target.value)}
                                />
                                <MdDeleteForever onClick={() => removeField(dataName, index, false)} size={25} style={index !== 0 ? { cursor: "pointer" } : { visibility: "hidden" }} />
                            </div>
                        )}
                        <CiSquarePlus size={32} style={{ cursor: "pointer" }} type="button" onClick={() => addField(dataName, false)} />
                    </div>
                )
            case "Object":
                return (
                    <div style={WrapperStyle}>
                        <label style={titleStyle}>{title}</label>
                        {data.map((item, index) => (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {input.map((inputItem) => (
                                    <div style={{ display: "flex" }}>
                                        <p style={PStyle}>{inputItem}:</p>
                                        <input
                                            style={InputStyle}
                                            type="text"
                                            name={inputItem}
                                            value={item[inputItem]}
                                            onChange={(event) => onchange(event, dataName, index)}
                                        />
                                    </div>
                                ))}
                                <MdDeleteForever onClick={() => removeField(dataName, index, true)} size={25} style={index !== 0 ? { cursor: "pointer" } : { visibility: "hidden" }} />
                            </div>
                        ))}
                        <CiSquarePlus size={32} style={{ cursor: "pointer" }} type="button" onClick={() => addField(dataName, input, true)} />
                    </div>
                )
            case "Unit":
                return (
                    <div style={WrapperStyle}>
                        <label style={titleStyle}>{title}</label>
                        {data.map((item, index) => (
                            <div key={index} style={{ display: "flex", alignItems: "center" }}>
                                {input.map((inputItem) => {
                                    if (inputItem == "unit") {
                                        return (
                                            <div style={{ display: "flex" }}>
                                                <p style={PStyle}>{inputItem}</p>
                                                <select
                                                    style={InputStyle}
                                                    name={inputItem}
                                                    value={item[inputItem]}
                                                    onChange={(event) => onchange(event, dataName, index)}
                                                >
                                                    {unitOptions.map((option, index) => (
                                                        <option key={index} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div style={{ display: "flex" }}>
                                                <p style={PStyle}>{inputItem}</p>
                                                <input
                                                    style={InputStyle}
                                                    type="text"
                                                    name={inputItem}
                                                    value={item[inputItem]}
                                                    onChange={(event) => onchange(event, dataName, index)}
                                                />
                                            </div>
                                        )
                                    }
                                })}
                                <MdDeleteForever onClick={() => removeField(dataName, index, true)} size={25} style={dataName !== "declaredUnits" ? { cursor: "pointer" } : { visibility: "hidden" }} />
                            </div>
                        ))}
                        {(dataName !== "declaredUnits" || (dataName === "declaredUnits" && data.length === 0)) && (
                            <CiSquarePlus
                                size={32}
                                style={{ cursor: "pointer" }}
                                type="button"
                                onClick={() => addField(dataName, input, true)}
                            />
                        )}

                    </div >
                )
            case "Massefylde":
                return (
                    <div style={WrapperStyle}>
                        <label style={titleStyle}>{title}</label>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={PStyle}>Density:</p>
                                <input
                                    style={InputStyle}
                                    type="text"
                                    name="value"
                                    value={data?.density?.value || ""}
                                    onChange={(event) => onchange(event, "density")}
                                />
                                <span>kg / </span>
                                <select
                                    style={InputStyle}
                                    name="unit"
                                    value={data?.density?.unit || ""}
                                    onChange={(event) => onchange(event, "density")}
                                >
                                    {MassefyldeOptions.map((option, optionIndex) => (
                                        <option key={optionIndex} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={PStyle}>Volume:</p>
                                <input
                                    style={InputStyle}
                                    type="text"
                                    name="value"
                                    value={data?.volume?.value || ""}
                                    onChange={(event) => onchange(event, "volume")}
                                />
                                <select
                                    style={InputStyle}
                                    name="unit"
                                    value={data?.volume?.unit || ""}
                                    onChange={(event) => onchange(event, "volume")}
                                >
                                    {MassefyldeOptions.map((option, optionIndex) => (
                                        <option key={optionIndex} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={PStyle}>Weight:</p>
                                <input
                                    style={InputStyle}
                                    type="text"
                                    name="weight"
                                    value={data?.weight || ""}
                                    onChange={(event) => onchange(event)}
                                />
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={PStyle}>Thickness:</p>
                                <input
                                    style={InputStyle}
                                    type="text"
                                    name="thickness"
                                    value={data?.thickness || ""}
                                    onChange={(event) => onchange(event)}
                                />
                            </div>

                        </div>
                    </div>
                )
            case "BuyLink":
                return (
                    <div style={WrapperStyle}>
                        <label style={titleStyle}>{title}</label>
                        <div style={{ display: "flex" }}>
                            <p style={PStyle}>{title}:</p>
                            <input
                                style={InputStyle}
                                type="text"
                                name={dataName}
                                value={data}
                                onChange={(event) => onchange(event, dataName)}
                            />
                        </div>
                    </div>
                )
            default:
                return (
                    <div>hej smukke</div>
                )
        }
    }

    return (
        <div>
            {renderContent()}
        </div>
    )
}
CorrectForm.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    data: PropTypes.any.isRequired,
    onchange: PropTypes.func.isRequired,
    categories: PropTypes.array,
    input: PropTypes.array,
    dataName: PropTypes.string,
    addField: PropTypes.func
};

export default CorrectForm;