
// import MenuProfile from "../../components/menuProfile";

function settings()  {
    return (
        <div>
        
            
            <h1>Settings</h1>
        </div>
    )
}

export default settings;