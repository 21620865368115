import React, { useEffect, useState } from "react";
import StyledButton from "../../../../components/StyledButton";
import { ValidateNewVersion } from '../../../../components/APIS/VersionAPI';
import { useNavigate } from 'react-router-dom';
import Loader from "../../../../components/Utils/Loader";

function VersionAccept({ data }) {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const AcceptWrapper = {

        backgroundColor: "var(--Grey)",
        margin: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "10px",
        maxWidth: "400px",
        height: "500px",
        padding: "10px",
        position: "sticky",
        top: "20px",
    }

    const SubmitVersion = async () => {
        setLoading(true);
        await ValidateNewVersion(data.id, true);
        navigate(`/produkter?tap=Version+Control`);
    }

    const CorrectVersion = async () => {
        await ValidateNewVersion(data.id, false);
        console.log(data);
        const correct = data.newversion
        navigate(`/produkter/Correct/${data.newversion.id}`, {state: {data: correct, version: true}});
    }

    return (
        <div style={AcceptWrapper}>
            <h2 style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>Resolve Version</h2>
            <label>
                *Differences between the versions will be marked with red color
            </label>
            <label>
                If the new version is up to date please press the "Godkend" button
            </label>
            <label>
                Otherwise a correction is requried, please press the "Ret" button, this will lead to a correction page
            </label>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>

                <StyledButton
                    title={"Ret"}
                    onClick={CorrectVersion}
                />

                <StyledButton
                    title={"Godkend"}
                    onClick={SubmitVersion}
                />
            </div>
            {!loading ? ("") : (<Loader sizex={50} />)}
            
        </div>
    )
}

export default VersionAccept;