import React from "react";
import PropTypes from 'prop-types';

function StyledButton({ title, onClick }) {
    const buttonStyle = { 
        margin: "30px", 
        border: "none", 
        padding: "10px", 
        cursor: "pointer", 
        borderRadius: "5px", 
        backgroundColor: "var(--Sand)", 
        color: "var(--White)" 
    };

    return (
        <button style={buttonStyle} onClick={onClick}>{title}</button>
    );
}

StyledButton.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func
};

export default StyledButton;
