import React from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import './loader.css';

const Loader = ({ sizex = 24, colors = 'Black' }) => {
    
    const colorCodes = {
        Green: "var(--Green)",
        Sand: "var(--Sand)",
        White: "var(--White)",
        Black: "var(--Black)",
        Grey: "var(--Grey)"
    };

    // Handle invalid colors by providing a default color (Black)
    const loaderColor = colorCodes[colors] || colorCodes.Black;

    return (
        <div className="loader">
            <AiOutlineLoading className="loader-icon" size={sizex} color={loaderColor} />
        </div>
    );
};

export default Loader;
