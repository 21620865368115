import React from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import styles from './FilterCategory.module.css'; // Assuming you have saved the CSS as a module

function FilterCategory({ categoryName, isActive, onClickHandler, children }) {
  return (
    <div className={styles.filterCategoryContainer}>
      {categoryName === 'GWP' ? (
        <div className={styles.filterCategory} onClick={() => onClickHandler(categoryName)}>
          {categoryName} <p>(Global Warming Potential)</p>
          <IoIosArrowDown className={`${styles.arrowIcon} ${isActive ? styles.active : ''}`} />
        </div>
      ) : categoryName === 'Levetid' ? (
        <div className={styles.filterCategory} onClick={() => onClickHandler(categoryName)}>
          {categoryName} <p>(RSL)</p>
          <IoIosArrowDown className={`${styles.arrowIcon} ${isActive ? styles.active : ''}`} />
        </div>
      ) : (
        <div className={styles.filterCategory} onClick={() => onClickHandler(categoryName)}>
          {categoryName} <IoIosArrowDown className={`${styles.arrowIcon} ${isActive ? styles.active : ''}`} />
        </div>
      )}
      <div className={`${styles.filterContent} ${isActive ? styles.expand : styles.collapse}`}>
        {isActive && children}
      </div>
    </div>
  );
}

export default FilterCategory;
