import React, { useState } from 'react';
import { baseUrl, useGlobalContext } from "../GlobalContext";
import { useNavigate } from 'react-router-dom';
import './styles/LoginPage.css';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [loginError, setLoginError] = useState('');
    const {setUserType } = useGlobalContext();

    const setUserData = async () => {
        try {
            const response = await fetch(`${baseUrl}/User`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
                },
            });
            const data = await response.json();
            if (response.ok) {
                setUserType(data);
            } else {
                console.error(data.error);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoginError('');

        try {
            const response = await fetch(`${baseUrl}/Auth`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email, password: password }),
            });

            let data;
            const contentType = response.headers.get('content-type');

            if (contentType && contentType.includes('application/json')) {
                data = await response.json();
            } else {
                data = await response.text();
            }

            if (response.ok) {
                localStorage.setItem('sessionToken', data.sessionToken);
                await setUserData();
                navigate('/');
            } else if (response.status === 400 && data === 'Password reset required') {
                navigate('/reset-password');
            } else {
                setLoginError('Wrong email or password');
            }
        } catch (error) {
            console.error('Failed to login');
            setLoginError('An error occurred while trying to login. Please try again.');
        }
    };

    // Define the handleResetPasswordClick function
    const handleResetPasswordClick = () => {
        navigate('/reset-password');
    };

    return (
        <div className="page-style">
            <div className="login-cont">
                <div className="log-input-style">
                    <form onSubmit={handleLogin}>
                        <h3 className="font-style">Email</h3>
                        <input
                            type="text"
                            name="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setLoginError('');
                            }}
                            className="input-style"
                        />
                        <h3 className="font-style">Password</h3>
                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setLoginError('');
                            }}
                            className="input-style"
                        />
                        <div className="login-cont-buttons">
                            <button type="submit" className="button-style">Login</button>
                            <button
                                type="button"
                                onClick={handleResetPasswordClick}
                                className="button-style"
                            >
                                Reset Password
                            </button>
                        </div>
                        {loginError && <p className="login-error">{loginError}</p>}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
