import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  deleteFile,
  postFileProd, getClaimedEPD
} from '../../../components/APIS/uploadAPI';
import { GrDocumentVerified, GrTrash } from 'react-icons/gr';
import styles from './UploadRow.module.css';
import { fetchItemData } from '../../../components/APIS/categoryAPI';
import ProductRow from './ProductRow';
import Loader from '../../../components/Utils/Loader';

function UploadComponent() {
  const [allFiles, setAllFiles] = useState({});
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
    const [uploadProgress, setUploadProgress] = useState({});

  const LoadClaimProducts = async () => {
    try {
      let data = await getClaimedEPD();

      if (!Array.isArray(data.products)) {
        console.error('Unexpected data format:', data);
        throw new Error('Expected an array of products.');
      }

      setProducts(data.products);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const endpointMapping = {
    'image': 'Pdf/image',
    'technical-specification': 'Pdf/technical-specification',
    'BrugsBlad': 'Pdf/BrugsBlad',
    'other': 'Pdf/Other',
    'certifications': 'Pdf/certification',
  };

  const handleFileUpload = async (productId, file, fileType, additionalParams = {}) => {
    const endpoint = endpointMapping[fileType];

    // console.log("endpoint :", endpoint);

    if (!endpoint) {
      console.error(`Unknown file type: ${fileType}`);
      return;
    }

    try {
      await postFileProd(productId, file, endpoint, additionalParams, (progress) => {
        setUploadProgress((prevProgress) => {
          const productProgress = prevProgress[productId] || {};
          return {
            ...prevProgress,
            [productId]: { ...productProgress, [fileType]: progress },
          };
        });
      });
    } catch (error) {
      console.error(`Error uploading ${fileType} for product ${productId}:`, error);
    } finally {
      setUploadProgress((prevProgress) => {
        const productProgress = prevProgress[productId] || {};
        return {
          ...prevProgress,
          [productId]: { ...productProgress, [fileType]: undefined },
        };
      });
    }
  };

  // Collect files from each row
  const handleFilesChange = (productId, files) => {
    setAllFiles((prevFiles) => ({
      ...prevFiles,
      [productId]: files,
    }));
  };

  useEffect(() => {
    LoadClaimProducts();
  }, []);

  const handleSubmit = async () => {
    for (const product of products) {
      const productId = product.id;
      const productFiles = allFiles[productId] || {};
  
      // Handle file uploads
      for (const [fileType, fileData] of Object.entries(productFiles)) {
        if (fileType === 'image' || fileType === 'other') {
          const filesArray = Array.isArray(fileData) ? fileData : [fileData];
          for (const file of filesArray) {
            if (file instanceof File) {
              await handleFileUpload(productId, file, fileType);
            }
          }
        } else if (fileType === 'certifications') {
          const certificationsData = fileData; // fileData is an object mapping certId to certFile
          for (const [certId, certFile] of Object.entries(certificationsData)) {
            if (certFile instanceof File) {
              const additionalParams = { certificationType: certId };
              await handleFileUpload(productId, certFile, fileType, additionalParams);
            }
          }
        } else if (fileData instanceof File) {
          await handleFileUpload(productId, fileData, fileType);
        }
      }
    }
  
    // Reload data after uploads
    await LoadClaimProducts();
  };
  
const loading_loader =<tr> <Loader sizex={24} colors="Var(--Green" /></tr>;

const error_loader = <tr> <div>Error loading products.</div> </tr>;

  return (
    <div>
      <table className={styles.uploadTable}>
        <thead>
          <tr>
            <th>Navn</th>
            <th>Billede</th>
            <th>Technical Specifications</th>
            <th>Brugsblad</th>
            <th>Certifikationer</th>
            <th>Andre</th>
          </tr>
        </thead>
        <tbody>
          {loading && loading_loader}
          {error && error_loader}
          {products.map((product) => (
            <ProductRow
              key={product.id}
              product={product}
              onFilesChange={handleFilesChange}
              reloadData={LoadClaimProducts}
            />
          ))}
        </tbody>
      </table>
      <button className={styles.submitButn} onClick={handleSubmit}> Submit </button>
    </div>
  );
}


export default UploadComponent;
