import { useParams, useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { fetchDatafromQuery, fetchQueryDataCat } from '../../../components/APIS/categoryAPI';
import MemoizedDetailBoxes from '../LoadedProducts';
import FilterQuery from '../Filter/Filter';
import styles from './kategoridetailstyle.module.css'; // Import the CSS module
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import Loader from '../../../components/Utils/Loader';

function KategoriDetailPage() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [dat, setDat] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [unit, setUnit] = useState({});
  const [availableUnits, setAvailableUnits] = useState([]);
  const [orgData, setOrgData] = useState({});
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(100);
  const [productLoad, setProductLoad] = useState(true);
  const [query, setQuery] = useState({});
  const [isSubkategori, setIsSubkategori] = useState(location.pathname.includes('/subkategori/'));


  const [title , setTitle] = useState('');
  const formatSubcategory = (name) => {
    return name
      .replace(/ae/g, 'æ')                  
      .replace(/([A-Z])/g, ' $1')             
      .replace(/^./, str => str.toUpperCase()); 
  };
  const titleCheck = () => {
    if (isSubkategori) {
      setTitle(formatSubcategory(id));
    } else {
      setTitle(id);
    }
  };




  const fetchData = async () => {
    setIsLoading(true);
    try {
      let data = [];
      if(isSubkategori){
        const lcID =id.replace(/-/g, ' ');
        data = await fetchQueryDataCat({ id: lcID});
      }else{
        const lcID = categoryswitch(id);
        data = await fetchQueryDataCat({ id: lcID });
      }
      setOrgData(data);

      const availableUnits = data.map((item) => item.unit);
      setAvailableUnits([...new Set(availableUnits)]);
      let selectedUnit = '';

      if (availableUnits.includes('kg')) {
        selectedUnit = 'kg';
      } else {
        selectedUnit = availableUnits[Math.floor(Math.random() * availableUnits.length)];
      }

      const selectedData = data.filter((item) => item.unit === selectedUnit);
      const selectedObject = selectedData.length > 0 ? selectedData[0] : null;

      setUnit(selectedUnit);
      setDat(selectedObject);
      // console.log(dat);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch data on component load
  useEffect(() => {
    fetchData();
    titleCheck();
  }, []);

  const handleUnitChange = (e) => {
    const selectedData = orgData.filter((item) => item.unit === e.target.value);
    const selectedObject = selectedData.length > 0 ? selectedData[0] : null;
    setUnit(e.target.value);
    setDat(selectedObject);
    setPage(1);
  };

  const categoryswitch = (cat) => {

    switch (cat) {
      case 'Facade':
        return 'facade';
      case 'Fundament':
        return 'fundament';
      case 'Gulv':
        return 'gulv';
      case 'Indervæg':
        return 'inderveg';
      case 'Isolering':
        return 'isolering';
      case 'Konstruktion':
        return 'konstruktion';
      case 'Loft':
        return 'loft';
      case 'Præfabrikerede Elementer':
        return 'prefabrikeredeElementer';
      case 'Tag':
        return 'tag';
      default:
        return cat.toLowerCase();
    }
  };
  
  const handleUnitBox = () => {
    return (
      <div className={styles.unitBox}>
        {availableUnits.map((unitOption) => (
          <div
            key={unitOption}
            onClick={() => handleUnitChange({ target: { value: unitOption } })}
            className={`${styles.unitOption} ${unit === unitOption ? styles.selected : ''}`}
          >
            {unitOption}
          </div>
        ))}
      </div>
    );
  };

  // Add productLoad set to true when changing pages
  const pagePlus = () => {
    setProductLoad(true); // Show loader on page change
    setPage((prevPage) => Math.min(prevPage + 1, maxPage));
  };

  const pageMinus = () => {
    setProductLoad(true); // Show loader on page change
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageSelect = (pageNum) => {
    setProductLoad(true); // Show loader on page change
    setPage(pageNum);
  };

  const renderPagebtn = (max, current) => {
    if (max === 1) {
      return <p className={styles.pageNumber}>Side: 1</p>;
    }

    let pages = [];

    if (max <= 6) {
      pages = Array.from({ length: max }, (_, i) => i + 1);
    } else if (current <= 3) {
      pages = [1, 2, 3, 4, 5, '...', max];
    } else if (current >= max - 2) {
      pages = [1, '...', max - 4, max - 3, max - 2, max - 1, max];
    } else {
      pages = [
        1,
        '...',
        current - 2,
        current - 1,
        current,
        current + 1,
        current + 2,
        '...',
        max,
      ];
    }

    return (
      <div className={styles.numberBox}>
        {current > 1 && (
          <button className={styles.navButton} onClick={pageMinus}>
            <IoIosArrowBack className={styles.arrowIcon} />
          </button>
        )}

        {pages.map((pageNum, index) => (
          <span key={index}>
            {pageNum === '...' ? (
              <span className={styles.dots}>. . .</span>
            ) : (
              <button
                className={`${styles.unitOption} ${pageNum === current ? styles.selected : ''}`}
                onClick={() => handlePageSelect(pageNum)}
              >
                {pageNum}
              </button>
            )}
          </span>
        ))}

        {current < max && (
          <button className={styles.navButton} onClick={pagePlus}>
            <IoIosArrowForward className={styles.arrowIcon} />
          </button>
        )}
      </div>
    );
  };

  // Fetch products when query or page changes
  useEffect(() => {
    if (Object.keys(query).length > 0) {
      const updatedQuery = { ...query, pageNumber: page };
      fetchDatafromQuery(updatedQuery,isSubkategori)
        .then((data) => {
          setProducts(data.data);
          setMaxPage(data.maxPageNumber);
          setProductLoad(false);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setProductLoad(false);
        });
    }
  }, [query, page]);
  

  return (
    <div className={styles.pageContainer}>
      <div className={styles.filterContainer}>
        <h3>Filter</h3>
        <div>
          {!isLoading && dat && (
            <FilterQuery
              setQuery={setQuery}
              data={dat}
              setProducts={setProducts}
              pageN={page}
              setPageN={setPage}
              setMaxPage={setMaxPage}
              unit={unit}
              setUnit={setUnit}
              setProductLoad={setProductLoad}
              subCategory={id}
            />
          )}
        </div>
      </div>
      
      <div className="content-container">
        <div id="title_and_buttons">
          <div id="kategori-title">
            <h1>{title}</h1>
          </div>
          <div style={{ padding: '8px' }}>
            {!isLoading && dat && handleUnitBox()}
          </div>
        </div>
        <div>{!isLoading && dat && renderPagebtn(maxPage, page)}</div>
        {productLoad ? (
          <div className={styles.loader}>
            <Loader sizex={150} colors="Green" />{' '}
          </div>
        ) : products && products.length > 0 ? (
          <div className={styles.detailBoxGroup}>
            <MemoizedDetailBoxes
              isLoading={productLoad}
              products={products}
              Unit={unit}
              category={dat.category}
            />
          </div>
        ) : (
          <p>No products matching current filter</p>
        )}
        <div>{!isLoading && dat && renderPagebtn(maxPage, page)}</div>
      </div>
    </div>
  );
}

export default KategoriDetailPage;
