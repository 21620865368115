import { useState } from 'react';
import styles from './table.module.css'; // Import the CSS module
import SafetyLevelIcon from '../../components/safetylevel';
import UnitTranslator from '../../components/Utils/UnitTrans';
function NumberWithHover({ number }) {
  // Check if the number is defined and is a number
  if (number === null || number === undefined || typeof number !== 'number' || isNaN(number)) {
    return <div className={styles.numberContainerFilter}>Invalid data</div>;
  }

  // Converts the number to scientific notation
  const scientificNumber = number.toExponential(2);

  // Formats the number to a fixed number of decimal places for the tooltip
  const fullNumber = number.toFixed(2);

  return (
    <div className={styles.numberContainer} title={fullNumber}>
      <div>{scientificNumber}</div> {/* Set a fixed width */}
    </div>
  );
}

function MaterialTable({ title, data, selectedUnit, onUnitChange,safetyLevel }) {
  const { categories, rows, units } = data;

  // Function to check if a value is a number and return NumberWithHover component or plain value
  const renderCell = (cell) => {
    // Parse the cell to a float to check if it's a valid number
    const hoverText = () => {
      return <div>cell</div>;

    }
    const number = parseFloat(cell);
    // Check if the parsed number is a finite number and equal to the cell value (to avoid false positives with strings)
    if (!isNaN(number) && isFinite(number) && number.toString() === cell.toString() && title === 'Environmental Impact') {
      return <NumberWithHover number={number} />;
    } else {
      let unit = UnitTranslator(cell);
      return (
        <span title={unit}>
        {unit}
      </span>
      );
    }
  };

  return (
    <div className={styles.tableContainer}>
      <h2 className={styles.tableTitle}>{title}</h2>
      {title === 'Environmental Impact' && units && units.length > 0 && (
        <div className={styles.unitSelector}>
           Safety Level:  {SafetyLevelIcon({safetyLevel},32)}
          {units.map((unit, idx) => (
            <div key={idx}>
              <input
                type="radio"
                id={`unit-${idx}`}
                className={styles.unitSelectorInput}
                value={unit}
                checked={selectedUnit === unit}
                onChange={(e) => onUnitChange(e.target.value)}
              />
              <label htmlFor={`unit-${idx}`}>{unit}</label>
            </div>
          ))}
         
        </div>
      )}

      <div className={styles.materialTable}>
        <table>
          <thead>
            <tr>
              {categories.map((category, idx) => (
                <th key={idx}>{category}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => (
              <tr key={`row-${i}`}>
                {row.map((cell, idx) => (
                  <td 
                  style={{
                    width: '65px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  key={`cell-${i}-${idx}`}>
                    {renderCell(cell)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>

          <caption className={styles.captionTable} data-empty={title !== 'Environmental Impact'}>
            {title === 'Environmental Impact' &&
              'The numbers are declared in scientific notation, e.g., 1.95e+02. This number can also be written as: 1.95x10² or 195, while 1.2e-4 is the same as 0.12x10⁻⁴ or 0.00012. Hover a number to see the full value.'}
          </caption>
        </table>
      </div>
    </div>
  );
}

export default MaterialTable;
