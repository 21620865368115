import FilterCategory from "./cmp/filterCategory";
import styles from "./filter.module.css";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { NumberWithHover } from "./cmp/cmpNHover";
import { fetchDatafromQuery } from "../../../components/APIS/categoryAPI";

function FilterQuery({
  setQuery,
  data,
  setProducts,
  pageN,
  setPageN,
  pageS,
  setMaxPage,
  unit,
  setUnit,
  setProductLoad,
}) {
  const [activeFilterCategory, setActiveFilterCategory] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const [maxHeight, setMaxHeight] = useState({
    GWP: "0",
    Standard: "0",
    Producent: "0",
    "Gyldig til": "0",
    "Safety Level": "0",
    Brandklasse: "0",
    Certifikationer: "0",
  });

  // Initialize filters from URL parameters
  const initialFilters = useMemo(() => {
    if (data && Object.keys(data).length > 0) {
      const searchParams = new URLSearchParams(location.search);

      const compliances = searchParams.getAll("compliance");
      const producers = searchParams.getAll("producer");
      const brandValues = searchParams.getAll("brandValue");
      const validTo = searchParams.get("validTo");
      const certifications = searchParams.getAll("certification");
      const safetyLevelMax = searchParams.get("safetyLevelMax")
      const gwpValues = {
        a1A3: searchParams.get("a1A3"),
        a5: searchParams.get("a5"),
        b3: searchParams.get("b3"),
        c1: searchParams.get("c1"),
        c2: searchParams.get("c2"),
        c3: searchParams.get("c3"),
        c4: searchParams.get("c4"),
        d: searchParams.get("d"),
      };

      // Convert numeric values
      const numericGwpValues = {};
      Object.keys(gwpValues).forEach((key) => {
        if (gwpValues[key] !== null) {
          numericGwpValues[key] = parseFloat(gwpValues[key]);
        }
      });

      // Parse validTo
      const parsedValidTo = validTo ? parseInt(validTo, 10) : null;
      const parsedSafetyLevelMax = safetyLevelMax ? parseInt(safetyLevelMax, 10) : null;

      return {
        compliances: compliances.length > 0 ? compliances : [],
        producers: producers.length > 0 ? producers : [],
        brandValue: brandValues.length > 0 ? brandValues : [],
        validTo: parsedValidTo,
        certifications: certifications.length > 0 ? certifications : [],
        safetyLevelMax: parsedSafetyLevelMax,
        gwpValues: {
          a1A3: numericGwpValues.a1A3 || null,
          a5: numericGwpValues.a5 || null,
          b3: numericGwpValues.b3 || null,
          c1: numericGwpValues.c1 || null,
          c2: numericGwpValues.c2 || null,
          c3: numericGwpValues.c3 || null,
          c4: numericGwpValues.c4 || null,
          d: numericGwpValues.d || null,
        },
        unit: "kg",
      };
    } else {
      // Default filters when data is not available yet
      return {
        compliances: [],
        producers: [],
        brandValue: [],
        validTo: null,
        certifications: [],
        safetyLevelMax: null,
        gwpValues: {
          a1A3: null,
          a5: null,
          b3: null,
          c1: null,
          c2: null,
          c3: null,
          c4: null,
          d: null,
        },
        unit: "kg",
      };
    }
  }, [location.search, data]);

  const [filters, setFilters] = useState(initialFilters);
  const [newFilters, setNewFilters] = useState(initialFilters);

  const renderResetButton = () => (
    <button className={styles.resetbutton} onClick={resetFilters}>
      Reset
    </button>
  );

  const renderSearchButton = () => (
    <button className={styles.resetbutton} onClick={Search}>
      Search
    </button>
  );

  const resetFilters = () => {
    setActiveFilterCategory(null);
    const initialFilters = {
      compliances: [],
      producers: [],
      brandValue: [],
      validTo: null,
      certifications: [],
      safetyLevelMax: null,
      gwpValues: {
        a1A3: null,
        a5: null,
        b3: null,
        c1: null,
        c2: null,
        c3: null,
        c4: null,
        d: null,
      },
      unit: "kg",
    };
    setFilters(initialFilters);
    setNewFilters({ ...initialFilters });
    setPageN(1);
    navigate(`${location.pathname}`);
  };

  const Search = () => {
    setPageN(1);
    setFilters(newFilters);
    updateURLParams(newFilters);
  };

  // Function to update URL parameters based on filters
  const updateURLParams = (filtersToUpdate) => {
    const params = new URLSearchParams();

    if (filtersToUpdate.compliances.length > 0) {
      filtersToUpdate.compliances.forEach((compliance) =>
        params.append("compliance", compliance)
      );
    }

    if (filtersToUpdate.producers.length > 0) {
      filtersToUpdate.producers.forEach((producer) =>
        params.append("producer", producer)
      );
    }

    if (filtersToUpdate.brandValue.length > 0) {
      filtersToUpdate.brandValue.forEach((brand) =>
        params.append("brandValue", brand)
      );
    }

    if (filtersToUpdate.certifications.length > 0) {
      filtersToUpdate.certifications.forEach((cert) =>
        params.append("certification", cert)
      );
    }

    if (filtersToUpdate.validTo) {
      params.set("validTo", filtersToUpdate.validTo);
    }
    
    if (filtersToUpdate.safetyLevelMax)
    {
      params.set("safetyLevelMax", filtersToUpdate.safetyLevelMax);
    }

    Object.entries(filtersToUpdate.gwpValues).forEach(([key, value]) => {
      if (value !== null) {
        params.set(key, value);
      }
    });

    // Update the URL without reloading the page
    navigate(`${location.pathname}?${params.toString()}`);
  };

  // Debounce function to prevent rapid firing of events
  const debounce = (func, delay) => {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), delay);
    };
  };

  // Fetch data based on the current filters
  const fetchCarddata = async (query) => {
    try {
      // console.log(query);
      const data = await fetchDatafromQuery(query);
      // console.log(data);
      setProducts(data.data);
      setMaxPage(data.maxPageNumber);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setProductLoad(false);
    }
  };

  // Debounced function to update query and fetch data
  const debouncedSetQuery = useCallback(
    debounce((filtersToUse) => {
      if (data && Object.keys(data).length > 0) {
        const newQuery = {
          pageSize: pageS || 10,
          pageNumber: pageN || 1,
          compliances:
            filtersToUse.compliances?.length > 0 ? filtersToUse.compliances : [],
          producers:
            filtersToUse.producers?.length > 0 ? filtersToUse.producers : [],
          brandKlasseUnits:
            filtersToUse.brandValue?.length > 0 ? filtersToUse.brandValue : [],
          certifications:
            filtersToUse.certifications?.length > 0
              ? filtersToUse.certifications
              : [],
          validTo: filtersToUse.validTo || null,
          strings: [],
          safetyLevelMax: filtersToUse.safetyLevelMax,
          category: data.category,
          unit: data.unit,
          a1A3: filtersToUse.gwpValues?.a1A3 || null,
          a5: filtersToUse.gwpValues?.a5 || null,
          b3: filtersToUse.gwpValues?.b3 || null,
          c1: filtersToUse.gwpValues?.c1 || null,
          c2: filtersToUse.gwpValues?.c2 || null,
          c3: filtersToUse.gwpValues?.c3 || null,
          c4: filtersToUse.gwpValues?.c4 || null,
          d: filtersToUse.gwpValues?.d || null,
        };

        fetchCarddata(newQuery);
        setQuery(newQuery);
      }
    }, 500),
    [data, pageN, pageS]
  );

  // Update data whenever filters change
  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      debouncedSetQuery(filters);
    }
  }, [filters, data]);

  // Handle filter category clicks to expand/collapse sections
  const handleFilterCategoryClick = (category) => {
    const newMaxHeight = activeFilterCategory === category ? "0" : "2000px";
    setActiveFilterCategory(activeFilterCategory === category ? null : category);
    setMaxHeight({ ...maxHeight, [category]: newMaxHeight });
  };

  const handleGWPFilter = () => {
    if (!data || !data.gwpvalues) return null;

    const minMaxData = {};

    const gwp = data.gwpvalues;

    Object.entries(gwp).forEach(([key, value]) => {
      if (value == null) return;

      const baseKey = key.replace(/Min|Max$/, "");

      if (!minMaxData[baseKey]) {
        minMaxData[baseKey] = { min: null, max: null };
      }

      if (key.endsWith("Min")) {
        minMaxData[baseKey].min = value;
      } else if (key.endsWith("Max")) {
        minMaxData[baseKey].max = value;
      }
    });

    const handleSliderChange = (subCategory, newValue) => {
      setNewFilters((prevNewFilters) => ({
        ...prevNewFilters,
        gwpValues: {
          ...prevNewFilters.gwpValues,
          [subCategory]: parseFloat(newValue),
        },
      }));
    };

    return (
      <>
        {Object.entries(minMaxData).map(([key, { min, max }]) => {
          const isSameMinMax = min === max;
          const adjustedMin = isSameMinMax ? min - 1 : min;
          const adjustedMax = isSameMinMax ? max + 1 : max;

          return (
            <div
              key={key}
              className={styles.gwpslidercontainer}
              style={{ maxHeight: maxHeight.GWP }}
            >
              <label style={{ minWidth: "45px" }} htmlFor={key}>
                {key.toUpperCase()}
              </label>
              <input
                className={styles.sliderstyle}
                type="range"
                id={key}
                min={adjustedMin || 0}
                max={adjustedMax || 100}
                step={0.01}
                value={newFilters.gwpValues[key] || 0}
                onChange={(e) => handleSliderChange(key, e.target.value)}
                disabled={isSameMinMax}
              />
              <NumberWithHover number={newFilters.gwpValues[key]} />
            </div>
          );
        })}
      </>
    );
  };

  const handleComplianceFilter = () => {
    if (!data || !data.compliances) return null;

    const handleComplianceChange = (compliance) => {
      setNewFilters((prevNewFilters) => ({
        ...prevNewFilters,
        compliances: prevNewFilters.compliances.includes(compliance)
          ? prevNewFilters.compliances.filter((c) => c !== compliance)
          : [...prevNewFilters.compliances, compliance],
      }));
    };

    const compliancesArray = Array.from(new Set(data.compliances));

    return (
      <div style={{ maxHeight: maxHeight.Standard }}>
        {compliancesArray.map((compliance) => (
          <div key={compliance} className={styles.compliancecheckbox}>
            <input
              type="checkbox"
              id={compliance}
              name={compliance}
              checked={newFilters.compliances.includes(compliance)}
              onChange={() => handleComplianceChange(compliance)}
            />
            <label htmlFor={compliance}>{compliance}</label>
          </div>
        ))}
      </div>
    );
  };

  const handleProducerFilter = () => {
    if (!data || !data.producers) return null;

    const handleProducerChange = (producer) => {
      setNewFilters((prevNewFilters) => ({
        ...prevNewFilters,
        producers: prevNewFilters.producers.includes(producer)
          ? prevNewFilters.producers.filter((p) => p !== producer)
          : [...prevNewFilters.producers, producer],
      }));
    };

    const producersArray = Array.from(new Set(data.producers));

    return (
      <div style={{ maxHeight: maxHeight.Producent }}>
        {producersArray.map((producer) => (
          <div key={producer} className={styles.producercheckbox}>
            <input
              type="checkbox"
              id={producer}
              name={producer}
              checked={newFilters.producers.includes(producer)}
              onChange={() => handleProducerChange(producer)}
            />
            <label htmlFor={producer}>{producer}</label>
          </div>
        ))}
      </div>
    );
  };

  const handleFireRatingFilter = () => {
    if (!data || !data.brandValue) return null;

    const handleFireRatingChange = (fireRating) => {
      setNewFilters((prevFilters) => ({
        ...prevFilters,
        brandValue: prevFilters.brandValue.includes(fireRating)
          ? prevFilters.brandValue.filter((f) => f !== fireRating)
          : [...prevFilters.brandValue, fireRating],
      }));
    };

    const availableFireRatings = Array.from(new Set(data.brandValue));

    return (
      <div style={{ maxHeight: maxHeight.Brandklasse }}>
        {availableFireRatings.map((fireRating) => (
          <div key={fireRating} className={styles.fireRatingCheckbox}>
            <input
              type="checkbox"
              id={fireRating}
              name={fireRating}
              checked={newFilters.brandValue.includes(fireRating)}
              onChange={() => handleFireRatingChange(fireRating)}
            />
            <label htmlFor={fireRating}>{fireRating}</label>
          </div>
        ))}
      </div>
    );
  };

  const handleValidToFilter = () => {
    const currentYear = new Date().getFullYear();

    const handleValidToChange = (validTo) => {
      setNewFilters((prevNewFilters) => ({
        ...prevNewFilters,
        validTo: parseInt(validTo, 10),
      }));
    };

    return (
      <div
        className={styles.gwpslidercontainer}
        style={{ maxHeight: maxHeight["Gyldig til"] }}
      >
        <label style={{ minWidth: "45px" }} htmlFor="validTo">
          VALID TO
        </label>
        <input
          className={styles.sliderstyle}
          type="range"
          id="validTo"
          min={currentYear}
          max={currentYear + 15}
          step={1}
          value={newFilters.validTo || currentYear}
          onChange={(e) => handleValidToChange(e.target.value)}
        />
        <span className={styles.validToNumber}>
          {newFilters.validTo || currentYear}
        </span>
      </div>
    );
  };

  const handleSafetyLevelFilter = () => {

    const handleSafetyChange = (safetyLevelMax) => {
      setNewFilters((prevNewFilters) => ({
        ...prevNewFilters,
        safetyLevelMax: parseInt(safetyLevelMax, 10), // Convert to an integer
      }));
    };

    return (
      <div className={styles.gwpslidercontainer} style={{ maxHeight: maxHeight["Safety Level"] }}>
        <label style={{ minWidth: "45px" }} htmlFor="safetyLevelMax">
          Safety Level
        </label>
        <input
          className={styles.sliderstyle} // Apply the same styling as GWP values slider
          type="range"
          id="safetyLevelMax"
          min={0}
          max={3} 
          step={1}
          value={newFilters.safetyLevelMax ?? 0 }
          onChange={(e) => handleSafetyChange(e.target.value)}
        />
        <span className={styles.validToNumber}>{newFilters.safetyLevelMax ?? 0}</span> {/* Display as a number */}
      </div>
    );
  }

  const handleCertificationFilter = () => {
    if (!data || !data.certifications) return null;

    const handleCertificationChange = (certification) => {
      setNewFilters((prevNewFilters) => ({
        ...prevNewFilters,
        certifications: prevNewFilters.certifications.includes(certification)
          ? prevNewFilters.certifications.filter((c) => c !== certification)
          : [...prevNewFilters.certifications, certification],
      }));
    };

    const certificationsArray = Array.from(new Set(data.certifications));

    return (
      <div style={{ maxHeight: maxHeight.Certifikationer }}>
        {certificationsArray.map((certification) => (
          <div key={certification} className={styles.certificationcheckbox}>
            <input
              type="checkbox"
              id={`certification-${certification}`}
              name={`certification-${certification}`}
              checked={
                newFilters.certifications?.includes(certification) || false
              }
              onChange={() => handleCertificationChange(certification)}
            />
            <label htmlFor={`certification-${certification}`}>
              {certification}
            </label>
          </div>
        ))}
      </div>
    );
  };

  const renderFilters = (category) => {
    switch (category) {
      case "GWP":
        return handleGWPFilter();
      case "Gyldig til":
        return handleValidToFilter();
      case "Producent":
        return handleProducerFilter();
      case "Standard":
        return handleComplianceFilter();
      case "Brandklasse":
        return handleFireRatingFilter();
      case "Certifikationer":
        return handleCertificationFilter();
      case "Safety Level":
        return handleSafetyLevelFilter();
      default:
        return null;
    }
  };

  const renderFilterCategories = () => {
    const filterCategories = [
      "GWP",
      "Standard",
      "Producent",
      "Gyldig til",
      "Safety Level",
      "Certifikationer",
      "Brandklasse"
    ];
    return filterCategories.map((category) => (
      <FilterCategory
        key={category}
        categoryName={category}
        isActive={activeFilterCategory === category}
        onClickHandler={handleFilterCategoryClick}
      >
        {renderFilters(category)}
      </FilterCategory>
    ));
  };

  return (
    <div className={styles.filter}>
      <div style={{ display: "flex" }}>
        {renderResetButton()}
        {renderSearchButton()}
      </div>
      {renderFilterCategories()}
    </div>
  );
}

export default FilterQuery;
