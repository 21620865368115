import React from "react";
import '../styles/Team.css';


function Team() {
    
    const teamMembers = [
        {name: "Iben Nielsen",mail: "in@buildtivity.dk",title: "Direktør",imagePath: "/images/omOs/Ibsen.jpg",job:"Salg og Marketing"},
        {name: "Max Nødskov Møller",mail: "mnm@buildtivity.dk",title: "Co-founder",imagePath: "/images/omOs/Max.jpg",job:"Udvikler"},
        {name: "Simon Flyvbjerg Ledet",mail: "sfl@buildtivity.dk",title: "Co-founder",imagePath: "/images/omOs/Simon.jpg",job:"Branding og Økonomi"},
        {name: "Tobias Borregaard",mail: "trb@buildtivity.dk",title: "Co-founder",imagePath: "/images/omOs/Tobias.jpg",job:"Udvikler"}
    ]


    return (
        <div>
            <h1 className="team-header">Team</h1>
            <div className="team-container">
                {teamMembers.map(member => (
                    <div className="team-member" key={member.name}>
                        <div className="img-cont">
                            <img src={member.imagePath} alt={member.name} className="team-member-image" />
                        </div>
                        <div className="team-details">
                            <h2 className="team-name">{member.name}</h2>
                            <p className="team-title">{member.title},</p>
                            <p className="team-title">{member.job}</p>
                            <a href={`mailto:${member.mail}`} className="team-link">Mail: {member.mail}</a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Team; 