
import { baseUrl } from "../../GlobalContext";

export const CheckVerion = async () => {
    try {
        const response = await fetch(`${baseUrl}/Version/CheckClaimedProducts`, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('sessionToken')}`
            }
        });
        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }
        else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.log("there was an error", error);
        throw error;
    }
}

export const GetNewVersion = async () => {
    try {
        const response = await fetch(`${baseUrl}/Version/GetNewVersion`, {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('sessionToken')}`
            }
        });
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Error: ${response.status} ${response.statusText}. Server Response: ${errorText}`);
        }
        else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.log("there was an error", error);
        throw error;
    }
}

export const ValidateNewVersion = async (id, Accepted) => {
    try {
        const response = await fetch(`${baseUrl}/Version/ValidateNewVersion?NewVersionAccepted=${Accepted}&Id=${id}`, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('sessionToken')}`
            }
        });

        if(!response.ok){
            const errorText = await response.text();
            throw new Error(`Error: ${response.status} ${response.statusText}. Server Response: ${errorText}`);
        }
    }
    catch(error){
        console.error("there was an error", error);
        throw error;
    }
}