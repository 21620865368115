import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownAndModal } from '../../components/Utils/dropdownmodal';
import { MdDeleteForever } from 'react-icons/md';
import { deleteFromProject } from '../../components/APIS/postGetProject';
import SafetyBar from '../../components/SafetyBar';
import SafetyLevelIcon from '../../components/safetylevel';
import styles from './DetailBox.module.css'; // Import the CSS module

function limitWords(str, wordLimit) {
  const words = str.split(' ');
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(' ') + '...';
  }
  return str;
}

function NumberWithHover({ number }) {
  if (
    number === null ||
    number === undefined ||
    typeof number !== 'number' ||
    isNaN(number)
  ) {
    return <div className={styles.numberContainerFilter}>Invalid data</div>;
  }

  const scientificNumber = number.toExponential(2);
  const fullNumber = number.toFixed(2);

  return (
    <span className={styles.numberContainerFilter} title={fullNumber}>
      <span style={{ width: '70px' }}>{scientificNumber}</span>
    </span>
  );
}

function DetailBox({
  data = {},
  billede_id,
  Project,
  itemid,
  id,
  handledel,
  Unit,
  category,
}) {
  const [IndivUnit, setIndivUnit] = useState(Unit);

  useEffect(() => {
    setIndivUnit(Unit);
  }, [Unit]);

  const certificationImages = {
    CE: '/images/CE.svg',
    Cradle_to_cradle: '/images/CradletoCradle.svg',
    EU_Ecolabel: '/images/euecolabelpng.png',
    FSC: '/images/fsc-maerket.svg',
    indeklima_mark: '/images/indeklimamaerke.svg',
    PEFC: '/images/PEFC.svg',
    Svanemarke: '/images/svanemærke.svg',
    allergy_uk: '/images/allergy_uk.jpg',
    astmadanamrk: '/images/astmadanmark.jpg',
    derblueengel: '/images/Blauer_Engel_logo.svg',
  };

  const renderCertifications = () => {
    return (
      <React.Fragment>
        {data.ce > 0 && (
          <img
            src={certificationImages['CE']}
            alt="CE"
            className={styles.smallImagePlaceholder}
          />
        )}
        {data.svanemarke > 0 && (
          <img
            src={certificationImages['Svanemarke']}
            alt="Svanemarke"
            className={styles.smallImagePlaceholder}
          />
        )}
        {data.cradleToCradle > 0 && (
          <img
            src={certificationImages['Cradle_to_cradle']}
            alt="Cradle to Cradle"
            className={styles.smallImagePlaceholder}
          />
        )}
        {data.indeklimaMark > 0 && (
          <img
            src={certificationImages['indeklima_mark']}
            alt="Indeklima Mark"
            className={styles.smallImagePlaceholder}
          />
        )}
        {data.fsc > 0 && (
          <img
            src={certificationImages['FSC']}
            alt="FSC"
            className={styles.smallImagePlaceholder}
          />
        )}
        {data.pefc > 0 && (
          <img
            src={certificationImages['PEFC']}
            alt="PEFC"
            className={styles.smallImagePlaceholder}
          />
        )}
        {data.euEcolabel > 0 && (
          <img
            src={certificationImages['EU_Ecolabel']}
            alt="EU Ecolabel"
            className={styles.smallImagePlaceholder}
          />
        )}
        {data.derBlueEngel > 0 && (
          <img
            src={certificationImages['derblueengel']}
            alt="Der Blue Engel"
            className={styles.smallImagePlaceholder}
          />
        )}
        {data.atsmaMark > 0 && (
          <img
            src={certificationImages['astmadanmark']}
            alt="Atsma Mark"
            className={styles.smallImagePlaceholder}
          />
        )}
        {data.allergyUk > 0 && (
          <img
            src={certificationImages['allergy_uk']}
            alt="Allergy UK"
            className={styles.smallImagePlaceholder}
          />
        )}
      </React.Fragment>
    );
  };

  const handleDelete = async () => {
    console.log(id, itemid, category);
    await deleteFromProject(id, itemid, category);
    handledel();
  };

  const renderGWPTotal = () => {
    if (
      !data ||
      !data.gwpValues ||
      !Array.isArray(data.gwpValues) ||
      data.gwpValues.length === 0
    ) {
      return <p>Loading GWP data...</p>;
    }

    const gwpTotalEntry = data.gwpValues.find(
      (item) => item.gwpUnit === IndivUnit
    );

    if (!gwpTotalEntry) {
      return <p>No GWP data available for the selected unit.</p>;
    }
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {Object.keys(gwpTotalEntry).map((key) => {
          if (
            key === 'gwpUnit' ||
            !gwpTotalEntry[key] ||
            parseFloat(gwpTotalEntry[key]) === 0
          ) {
            return null;
          }

          const value = parseFloat(gwpTotalEntry[key]);

          return (
            <div
              key={key}
              style={{
                width: '40%',
                boxSizing: 'border-box',
                maxHeight: '30px',
                fontFamily: 'CenturyGothic',
                marginLeft: '5px',
              }}
            >
              <p>
                {key.toUpperCase()}: <NumberWithHover number={value} />
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  const renderSafetyLevel = () => {
    if (
      !data ||
      !data.declaredUnits ||
      !Array.isArray(data.declaredUnits) ||
      data.declaredUnits.length === 0
    ) {
      return <p>Loading safety level data...</p>;
    }

    const declaredUnitEntry = data.declaredUnits.find(
      (item) => item.unit === IndivUnit
    );

    if (
      !declaredUnitEntry ||
      declaredUnitEntry.safetyLevel === undefined ||
      declaredUnitEntry.safetyLevel === null
    ) {
      return <p>No Safety Level data available for the selected unit.</p>;
    }

    return (
      <div className={styles.safetyLevelContainer}>
        {SafetyLevelIcon({ safetyLevel: declaredUnitEntry.safetyLevel }, 38)}
      </div>
    );
  };

  const handleUnitChange = (e) => {
    setIndivUnit(e.target.value);
  };

  const renderCategorySelector = () => {
    const unitOrder = ['kg', 'ton', 'm2', 'm3', 'stk', 'm'];
    const Units = [
      ...new Set(data.declaredUnits.map((item) => item.unit)),
    ].sort((a, b) => unitOrder.indexOf(a) - unitOrder.indexOf(b));

    return (
      <div className={styles.unitBox}>
        {Units.map((unitOption) => (
          <div
            key={unitOption}
            className={`${styles.unitOption} ${
              IndivUnit === unitOption ? styles.unitOptionSelected : ''
            }`}
            onClick={() => handleUnitChange({ target: { value: unitOption } })}
          >
            {unitOption}
          </div>
        ))}
      </div>
    );
  };

  const renderTechnicalSpecifications = () => {
    if (!data.producentUploadData) {
      return <p>No brand data available</p>;
    }

    return (
      <div>
        {data.producentUploadData?.brand?.map((brandItem, index) => {
          if (brandItem.value) {
            const value = brandItem.value;
            return <p key={index}>{value}</p>;
          }
          return null;
        })}
      </div>
    );
  };

  return (
    <div
      className={`${styles.mainColumn} ${
        Project === true ? styles.mainColumnProject : ''
      }`}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}></div>
      <div style={{ display: 'flex', margin: '10px 0' }}>
        <Link
          to={{
            pathname: `/produkt/${data.id}`,
            search: `?from=${encodeURIComponent(category)}`,
          }}
        >
          <div
            className={styles.imageStyle}
            style={{ backgroundImage: `url(${billede_id})` }}
          ></div>
        </Link>
        <div className={styles.imageContainer}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingBottom: '10px',
              }}
            >
              <Link
                to={{
                  pathname: `/produkt/${data.id}`,
                  search: `?from=${encodeURIComponent(category)}`,
                }}
                className="detail-box-link"
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <h1 className={styles.titleStyle}>{data.productName}</h1>
              </Link>
              {!Project ? (
                <div>
                  <DropdownAndModal ItemId={data.id} category={category} />
                </div>
              ) : (
                <div style={{ cursor: 'pointer' }}>
                  <MdDeleteForever
                    onClick={handleDelete}
                    size={30}
                    color="var(--Green)"
                  />
                </div>
              )}
            </div>
          </div>

          <p style={{ color: '#212426', maxWidth: '90%' }}>
            {typeof data['description'] === 'string'
              ? limitWords(data['description'], 30)
              : 'N/A'}
          </p>
          <Link to={{ pathname: `/produkt/${data.id}` }}>
            <div className={styles.imagesBottom}>{renderCertifications()}</div>
          </Link>
        </div>

        <div style={{ paddingLeft: '20px', width: '290px', maxHeight: '350' }}>
          <div>{renderSafetyLevel()}</div>
          <div>
            <p className={styles.categoryStyle}>Producent:</p>
            <p className={styles.dataStyle}>
              {data.producerName && Object.keys(data.producerName).length > 0
                ? data.producerName
                : 'N/A'}
            </p>
          </div>

          <div>
            <p className={styles.categoryStyle}>Materiale:</p>
            <p className={styles.dataStyle}>{data.subCategory ?? 'N/A'}</p>
          </div>
          <div>
            <p className={styles.categoryStyle}>Brand Klasse:</p>
            <div className={styles.dataStyle}>{renderTechnicalSpecifications()}</div>
          </div>
          <div>
            <p className={styles.categoryStyle}>Enhed:</p>
            {renderCategorySelector()}
          </div>

          <div>
            <p className={styles.categoryStyle}>Environmental Impacts:</p>
            {renderGWPTotal()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailBox;
