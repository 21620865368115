import React, { useState, useEffect, useRef } from 'react';
import './projectcomp.css';



function DeleteProjectOverlay({ projects, onDelete, onClose }) {
  const [selectedProject, setSelectedProject] = useState(null);
  const [showConfirmOverlay, setShowConfirmOverlay] = useState(false);
  const modalContentRef = useRef(null);

  const handleDeleteClick = () => {
    if (selectedProject) {
      setShowConfirmOverlay(true);
    }
  };

  const confirmDelete = (event) => {
    event.stopPropagation();
    onDelete(selectedProject);
    setSelectedProject(null);
    setShowConfirmOverlay(false);
  };
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
        onClose(); // Call onClose without arguments
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]); // Include onClose in dependency array

  return (
    <div className="deleteProjectOverlay">
      <div className="deleteProjectContent" ref={modalContentRef}>
        <h2 style={{color:"var(--Green)",fontFamily:"AMIA"}}>Delete Project</h2>
        <div className="projectList">
          {projects.map((project, index) => (
            <div
              key={index}
              className={`projectItem ${selectedProject === project.id ? 'selected' : ''}`}
              onClick={() => 
                setSelectedProject(selectedProject === project.id ? null : project.id)
              }
            >
              {project.ProjectName}
            </div>
          ))}
        </div>
        <button onClick={handleDeleteClick} disabled={!selectedProject}>
          Delete Selected Project
        </button>
        {showConfirmOverlay && (
          <div className="confirmOverlay">
            <div className="confirmContent">
              <p>Are you sure you want to delete this project?</p>
              <button onClick={confirmDelete}>Yes, Delete</button>
              <button onClick={() => setShowConfirmOverlay(false)}>No, Cancel</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DeleteProjectOverlay;
