// CertificationModal.js
import React, { useState, useEffect, useCallback } from 'react';
import { GrTrash } from 'react-icons/gr';
import styles from './UploadRow.module.css';
import { useDropzone } from 'react-dropzone';


const certificationsList = {
  1: 'CE',
  2: 'Svanemarke',
  3: 'Cradle to Cradle',
  4: 'Indeklima Mark',
  5: 'FSC',
  6: 'PEFC',
  7: 'EU Ecolabel',
  8: 'Der Blue Engel',
  9: 'Astma Mark',
  10: 'Allergy UK',
};

const CertificationModal = ({
  show,
  onClose,
  onSave,
  selectedCertifications = [],
  certificationFiles = {},
}) => {
  const [localSelectedCerts, setLocalSelectedCerts] = useState({});
  const [localCertFiles, setLocalCertFiles] = useState({});

  useEffect(() => {
    if (show) {
      const initialSelected = {};
      const initialFiles = {};

      // Initialize selected certifications and map files by certification ID
      selectedCertifications.forEach((cert) => {
        const { certification, url } = cert;
        initialSelected[certification] = true;
        initialFiles[certification] = url ? { url } : null;
      });

      setLocalSelectedCerts(initialSelected);
      setLocalCertFiles(initialFiles);
    }
  }, [show, selectedCertifications]);

  const handleCertChange = (certificationId) => {
    setLocalSelectedCerts((prevSelected) => {
      const updatedSelected = { ...prevSelected };
      if (updatedSelected[certificationId]) {
        delete updatedSelected[certificationId];
        setLocalCertFiles((prevFiles) => {
          const updatedFiles = { ...prevFiles };
          delete updatedFiles[certificationId];
          return updatedFiles;
        });
      } else {
        updatedSelected[certificationId] = true;
      }
      return updatedSelected;
    });
  };

  const handleFileChange = (file, certId) => {
    setLocalCertFiles((prevFiles) => ({
      ...prevFiles,
      [certId]: file,
    }));
  };

  const FileDropzone = ({ certId }) => {
    const onDrop = useCallback(
      (acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
          const file = acceptedFiles[0];
          handleFileChange(file, certId);
        }
      },
      [certId]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    if (localCertFiles[certId]) {
      return (
        <div className={styles.fileContainer}>
          {localCertFiles[certId] instanceof File ? (
            <span>{localCertFiles[certId].name}</span>
          ) : (
             <a href={localCertFiles[certId].url} target="_blank" rel="noopener noreferrer" className={styles.fileLink}>
             View File
           </a>
          )}
          <GrTrash
            onClick={() => {
              setLocalCertFiles((prevFiles) => {
                const updatedFiles = { ...prevFiles };
                delete updatedFiles[certId];
                return updatedFiles;
              });
            }}
            className={styles.fileIconDelete}
          />
        </div>
      );
    }

    return (
      <div {...getRootProps()} className={styles.dropzone}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop file here...</p>
        ) : (
          <p>Click or drag file to upload</p>
        )}
      </div>
    );
  };

  const handleSave = () => {
    const combinedCertifications = {};
    Object.keys(localSelectedCerts).forEach((certId) => {
      combinedCertifications[certId] = localCertFiles[certId] || null;
    });

    onSave(localSelectedCerts, combinedCertifications);
    onClose();
  };

  if (!show) return null;
  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div
        className={styles.modalContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.modalContent}>
          <h3>Select Certifications and Upload Files</h3>
          {Object.keys(certificationsList).map((certId) => (
            <div key={certId}>
              <label>
                <input
                  type="checkbox"
                  checked={!!localSelectedCerts[certId]}
                  onChange={() => handleCertChange(certId)}
                />
                {certificationsList[certId]}
              </label>
              {localSelectedCerts[certId] && (
                <div>
                  <FileDropzone certId={certId} />
                </div>
              )}
            </div>
          ))}
          <button onClick={handleSave}>Save</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default CertificationModal;
