import React, { useState, useEffect } from 'react';
import { useGlobalContext } from '../GlobalContext';
import { CiSquarePlus } from 'react-icons/ci';
import './styles/ProjektPage.css';
import { NavLink } from 'react-router-dom';
import { getProjects, deleteProject } from '../components/APIS/postGetProject';
import { fetchItemData } from '../components/APIS/categoryAPI';
import DeleteProjectOverlay from './Project/projectcomp';
import { MdDeleteForever } from "react-icons/md";
import { CreateProjectModal } from './Project/createproject';

function ProjektPage() {
    const { selectedProjects, setSelectedProjects } = useGlobalContext();
    const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
    const [showDeleteOverlay, setShowDeleteOverlay] = useState(false);

    const fetchProjects = async () => {
        const projects = await getProjects();
        const updatedProjects = await Promise.all(
            projects.map(async (project) => {
                if (project.items && project.items.length > 0) {
                    const updatedItems = await Promise.all(
                        project.items.map(async (item) => {
                            const Ritem = await fetchItemData(item.id);
                            const imageId = (
                                Ritem.manualTypedData &&
                                Ritem.manualTypedData.producentUploadData &&
                                Array.isArray(Ritem.manualTypedData.producentUploadData.imageURLS) &&
                                Ritem.manualTypedData.producentUploadData.imageURLS.length > 0
                            ) ? Ritem.manualTypedData.producentUploadData.imageURLS[0].url : null;

                            return { ...item, imageId };
                        })
                    );
                    return { ...project, items: updatedItems };
                }
                return project;
            })
        );
        setSelectedProjects(updatedProjects);
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    const handleDeleteProject = async (id) => {
        await deleteProject(id);
        await fetchProjects();
        setShowDeleteOverlay(false); // Close the overlay after deletion
    };

 
    return (
        <div className="ProjektPage">
            <div className='work-area'>     
                <div className='proj-modal-container'>
                    <div className="proj-modal" onClick={() => setShowCreateProjectModal(true)}>
                        <CiSquarePlus size={32} />
                        {showCreateProjectModal && (
                            <CreateProjectModal 
                                showModal={showCreateProjectModal}
                                onProjectCreated={() => {
                                    setShowCreateProjectModal(false);
                                    fetchProjects();
                                }}
                                onClose={() => setShowCreateProjectModal(false)}
                            />
                        )}
                    </div>
                    <div className="proj-modal" onClick={() => setShowDeleteOverlay(true)}>
                        <MdDeleteForever size={32} />
                        {showDeleteOverlay && (
                            <DeleteProjectOverlay 
                                projects={selectedProjects} 
                                onDelete={handleDeleteProject}
                                onClose={() => setShowDeleteOverlay(false)} 
                            />
                        )}
                    </div>
                </div>

                <div className={`cont-box-grid`}>
                    {selectedProjects.map((project, index) => (
                        <NavLink key={index} to={`/project/${project.id}`} className="project-item">
                            <h2>{project.ProjectName}</h2>
                            <div className="project-boxes-container">
                                {project.items.slice(0, 3).map((item, itemIndex) => (
                                    item.imageId && 
                                    <img key={itemIndex} 
                                        className="project-box" 
                                        src={item.imageId} 
                                        alt="Project" />
                                ))}
                            </div>
                        </NavLink>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ProjektPage;
