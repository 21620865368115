import React, { useState, useEffect,useContext } from 'react';
import './FeedbackButton.css';
import FeedbackModal from './FeedbackModal';
import { useGlobalContext } from '../GlobalContext';

const FeedbackButton = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('sessionToken'));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userType } = useGlobalContext();
  
 
 
  useEffect(() => {
    setIsLoggedIn(!!localStorage.getItem('sessionToken'));
  }
  , [isLoggedIn,userType]);

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (!isLoggedIn) {
    return null; // Return null if not logged in
  }

  return (
    <>
      <button className="feedback-button" onClick={openModal}>
        Feedback
      </button>
      {isModalOpen && <FeedbackModal closeModal={closeModal} />}
    </>
  );
};

export default FeedbackButton;
