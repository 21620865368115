import React, { useState } from 'react';
import { useNavigate    } from "react-router-dom";
import { baseUrl } from "../GlobalContext";

import './styles/RegisterPage.css';

function RegisterPage() {


    const [passwordStrength, setPasswordStrength] = useState('weak');
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const navigate = useNavigate(); 


    const [formData, setFormData] = useState({
        Firstname: '',
        Lastname: '',
        Email: '',
        CVR: '',
        password: '',
        repeatPassword: '',
        Company: ''
    });

    const assessPasswordStrength = (password) => {
    if (password.length >= 12 && /[A-Z]/.test(password) && /[a-z]/.test(password) && /\d/.test(password) && /[1234567890]/.test(password)) {
        return 'strong';
    } else if (password.length >= 8) {
        return 'medium';
    } else {
        return 'weak';
    }
};
    
const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }));

    if (name === 'password') {
        setPasswordStrength(assessPasswordStrength(value));
    }
};


const handleEmailBlur = () => {
    // Reset email validation states
    setIsEmailValid(true);
    setEmailErrorMessage("");
};

    const inputFields = [
        { name: 'Firstname', type: 'text', placeholder: 'First name' },
        { name: 'Lastname', type: 'text', placeholder: 'Last name' },
        { name: 'Email', type: 'text', placeholder: 'Email' },
        { name: 'Company', type: 'text', placeholder: 'Company' },
        { name: 'CVR', type: 'text', placeholder: 'CVR' },
        { name: 'password', type: 'password', placeholder: 'Password' },
        { name: 'repeatPassword', type: 'password', placeholder: 'Repeat Password' },
    ];
    

    const onSubmit = async () => {
        // Check if passwords match
        if (formData.password !== formData.repeatPassword) {
            alert("Passwords do not match!");
            return;
        }
    
        // Check if email is valid
        if (!isEmailValid) {
            alert("Please enter a valid email!");
            return;
        }
    
        // Adjust formData to match API expectations
        const payload = {
            firstname: formData.Firstname,
            lastname: formData.Lastname,
            email: formData.Email,
            companyName: formData.Company,
            cvr: formData.CVR,
            password: formData.password
        };
    
        // Send the form data to your backend
        try {
            const response = await fetch(`${baseUrl}/User/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    
                },
                body: JSON.stringify(payload)
            });
    
            const data = await response.json();
    
            if (response.ok) {
                alert("Registration successful!");
                navigate('/login');
            } else {
                alert(data.error || "Registration failed.");
            }
        } catch (error) {
            console.error("Error during registration:", error);
            alert("An error occurred during registration. Please try again.");
        }
    };
    

    return (
        <div className="page-style">
            <div className="col">
                {inputFields.map(field => (
                    <div key={field.name}>
                        <input
                            name={field.name}
                            type={field.type}
                            placeholder={field.placeholder}
                            value={formData[field.name]}
                            onChange={handleChange}
                            onFocus={() => field.name === 'password' && setIsPasswordFocused(true)}
                            onBlur={() => {
                                if (field.name === 'password') {
                                    setIsPasswordFocused(false);
                                } else if (field.name === 'Email') {
                                    handleEmailBlur();
                                }
                            }}
                        />
                        {field.name === 'password' && isPasswordFocused && (
                            <div className={`password-strength-${passwordStrength}`} style={{width: '100%', height: '20px', marginTop: '5px', borderRadius: '5px'}}></div>
                        )}
                        {field.name === 'Email' && !isEmailValid && (
                            <div className="email-error-message">
                                {emailErrorMessage}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div className="col">
                <button onClick={onSubmit}>Register</button>
            </div>
        </div>
    );
}

    
    
                        
export default RegisterPage;
