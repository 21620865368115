
import { baseUrl } from "../../GlobalContext";




export const fetchEPDid = async (id) => {
        try {
          const response = await fetch(`${baseUrl}/Producent/getProduct?EPDID=${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
            }
          });
          

          if (response.status === 404) {
            throw new Error(`Product not found`);
          }
          else if (response.status === 401) {
            throw new Error(`Unauthorized`);
          }
          else if (response.status === 403) {
            throw new Error(`Forbidden`);
          }
          else if (!response.ok) {
            throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
          }
          if (response.ok) {
            const data = await response.json();
            return data;
          }
        } catch (error) {
          console.error('fetchEPDid:', error);
          return null;
        }
      }

      export const claimEPDid = async (b, idArray) => {
        try {
          // Ensure idArray is an array
          if (!Array.isArray(idArray)) {
            throw new Error('The id parameter must be an array.');
          }
      
          // Prepare the request body as a JSON array string
          const body = JSON.stringify(idArray);
      
          // console.log(body);
          // console.log(`${baseUrl}/Producent/ClaimProduct?troOgLove=${b}`);
      
          const response = await fetch(`${baseUrl}/Producent/ClaimProduct?troOgLove=${b}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
            },
            body: body  // Send the array directly as the request body
          });
      
          // Check if the request was successful
          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }
      
          // Parse the JSON response
      
        } catch (error) {
          console.error('There was an error claiming the EPD ID:', error);
          throw error;
        }
      };

      export const unClaimEPDid = async (id) => {
        try {
          // Prepare the request body as a JSON array string
          const body = JSON.stringify([id]);
      
          const response = await fetch(`${baseUrl}/Producent/UnclaimProduct`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'accept': '*/*',
              'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
            },
            body: body  // Send the array as the request body
          });
      
          // Check if the request was successful
          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Error: ${response.status} ${response.statusText}. Server Response: ${errorText}`);
          }
        } catch (error) {
          console.error('There was an error unclaiming the EPD ID:', error);
          throw error;
        }
      };
      

          
      
      export const getClaimedEPD = async () => {
        try {
          const response = await fetch(`${baseUrl}/Producent/GetClaimedProducts`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'authorization': `Bearer ${localStorage.getItem('sessionToken')}`
            }
          });
          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          } else {
            const data = await response.json();
            return data;
          }

        } catch (error) {
          console.error('There was an error getting claimed EPD:', error);
          throw error;
        }


      };


      export const GetCorrectedEPD = async () => {
        try {
          const response = await fetch (`${baseUrl}/Producent/GetCorrectedProducts`, {
            method: 'Get',            
            headers: {
              'Content-Type': 'application/json',
              'authorization': `Bearer ${localStorage.getItem('sessionToken')}`
            }
          });
          if (!response.ok)
          {
            throw new Error(`Error: ${response.statusText}`);
          } 
          else 
          {
            const data = await response.json();
            return data;
          }
        }
        catch (error)
        {
          console.log('There was an error getting corrected EPD:', error);
          throw error;
        }
      };

      export const CorrectProduct = async (ProductCorrectionRequest) => {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
        };
        const body = JSON.stringify(ProductCorrectionRequest);
        console.log(body);
        try {
        const response = await fetch (`${baseUrl}/Producent/CorrectManualData`, {
          method: 'POST',
          headers: headers,
          body: body
        });
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        } else {
          const data = await response.json();
          return data;
        }
    
      } catch (error) {
        console.error('There was an error correcting the product:', error);
        throw error;
      }
      };






// POST
// /Pdf/technical-specification
// /Pdf/certification
// /Pdf/image
// /Pdf/BrugsBlad
// /Pdf/Other
// /Pdf/ProcessStatus
export const postFileProd = (productId, file, endpoint, additionalParams = {}, onProgress) => {
  const headers = {
    'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`,
  };

  const formData = new FormData();
  formData.append('file', file);

  Object.entries(additionalParams).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const queryString = new URLSearchParams({ productId, ...additionalParams }).toString();
    xhr.open('POST', `${baseUrl}/${endpoint}?${queryString}`);

    // Set headers
    xhr.setRequestHeader('Authorization', headers['Authorization']);

    // Set responseType to 'json'
    xhr.responseType = 'json';

    // Track upload progress
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable && typeof onProgress === 'function') {
        const progress = (event.loaded / event.total) * 100;
        // console.log(`Upload progress: ${progress}%`); // Added console.log
        onProgress(progress);
      } else {
        console.log('Progress event not computable or onProgress is not a function');
      }
    };

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject(new Error(`Error: ${xhr.statusText}`));
      }
    };

    xhr.onerror = () => reject(new Error('Network error'));

    xhr.send(formData);
  });
};

export const deleteFile = async (productId, endpoint, additionalParams = {}) => {
  const headers = {
    'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`,
  };
  
  try {
    // Build query string with productId and any additional parameters
    const queryString = new URLSearchParams({ productId, ...additionalParams }).toString();
    // console.log(queryString);
    // Make the DELETE request
      const response = await fetch(`${baseUrl}/${endpoint}?${queryString}`, {
        method: 'DELETE',
        headers,
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
  
      // Check if response has content
      const contentLength = response.headers.get('content-length');
      if (contentLength && parseInt(contentLength) > 0) {
        // Parse and return response data if necessary
        const data = await response.json();
        return data;
      } else {
        // No content to parse; return null or a success message
        return null;
      }
    } catch (error) {
      console.error(`There was an error deleting from ${endpoint}:`, error);
      throw error;
    }
  };

  export const getProcessStatus = async (productId) => {
    try {
      const response = await fetch(`${baseUrl}/Pdf/ProcessStatus?productId=${productId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`,
        },
      });
  
      // Handle 500 or 404 specifically and return status 5
      if (response.status === 500 || response.status === 404) {
        return 5;
      }
  
      // If the response is not ok (but not 500 or 404), throw an error
      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
  
      const statusCode = await response.json();
  
      // Check if statusCode is in the expected format (number)
      if (typeof statusCode !== 'number') {
        throw new Error('Unexpected response format');
      }
  
      return statusCode;
    } catch (error) {
      // Log the error, but only return 5 if there's an actual network or other fetch-related error
      console.error('Error fetching process status:', error);
      return 5;
    }
  };
  