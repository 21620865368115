import React, { useState, useEffect } from 'react';
import { CheckVerion, GetNewVersion } from '../../../components/APIS/VersionAPI';
import styles from "../Version Control/VersionTable.module.css";
import { Navigate, NavLink } from 'react-router-dom';
import styles2 from "../Row/UploadRow.module.css";

function Version() {
    const [versions, setVersions] = useState([]);
    const [isChecking, setIsChecking] = useState(true);

    useEffect(() => {

        const fetchData = async () => {
            await Check();
            if (!isChecking) {
                const data = await fetchNewVersion();
                setVersions(data);
            }
        };
        fetchData();
    }, [isChecking]);

    const Check = async () => {
        await CheckVerion();
        setIsChecking(false);
    };

    const fetchNewVersion = async () => {
        var data = await GetNewVersion();
        return data;
    };
    return (
        <div style={{ display: "flex", flexDirection: "row", gap: "10%", justifyContent: "center" }}>
            <table className={styles2.uploadTable}>
                <thead>
                    <tr>
                        <th></th>
                        <th>Product</th>
                        <th>Old Version</th>
                        <th>New Version</th>
                    </tr>
                </thead>
                <tbody>
                    {versions.map((version, index) => (
                        <tr key={index}>
                            <td>
                                <NavLink className={styles.Button} to={`/produkter/ResolveVersion/${version.oldVersion.id}`} state={{ version }}>
                                    Resolve
                                </NavLink>
                            </td>
                            <td>{version.oldVersion.productName}</td>
                            <td>{version.oldVersion.version}</td>
                            <td>{version.newversion.version}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

}
export default Version;