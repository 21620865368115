import React from "react"
import PropTypes from 'prop-types';

function VersionComparison({ newV, oldV, title, type, tableprops }) {
    const wraperStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }

    const elements = {
        display: "flex",
        flexDirection: "row",
        gap: "5vw",
        margin: "4px",
        marginBottom: "16px"
    }

    const ListWrapper = {
        display: "flex",
        flexDirection: "column"
    }

    const TableStyle = {
        borderSpacing: "0px",
        textAlign: "center",
        paddingLeft: "5px",
        paddingRight: "5px",
    }

    const TableCell = {
        border: "2px solid var(--Sand)",
        textAlign: "center",
        width: "auto",
        height: "4vh",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        paddingLeft: "5px",
        paddingRight: "5px"
    }

    const IsDifferentStyle = {
        color: "red"
    }

    const IsDifferent = (a, b) => a !== b;

    const renderContent = () => {
        if (oldV === undefined || newV === undefined) {
            return (
                <div>
                    <p>No data</p>
                </div>
            )
        }

        switch (type) {
            case "Link":
                return (
                    <div style={elements}>
                        <a href={newV} target="_blank">View Document</a>
                        <a href={oldV} target="_blank">View Document</a>
                    </div>
                )
            case "List":
                return (
                    <div style={elements}>
                        <div style={ListWrapper}>
                            {oldV.map((item, index) => (
                                <span key={index}>{item}</span>
                            ))}
                        </div>
                        <div style={ListWrapper}>
                            {newV.map((item, index) => (
                                <span
                                    key={index}
                                    style={IsDifferent(item, oldV[index]) ? IsDifferentStyle : {}}
                                >
                                    {item}
                                </span>
                            ))}
                        </div>
                    </div>
                )
            case "Table":
                return (
                    <div style={elements}>
                        <table style={TableStyle}>
                            <thead>
                                {tableprops.map((item, index) => (
                                    <th key={index} style={TableCell}>{item}</th>
                                ))}
                            </thead>
                            <tbody>
                                {oldV.map((item, rowIndex) => (
                                    <tr key={`old-row-${rowIndex}`}>
                                        {tableprops.map((col, colIndex) => (
                                            <td key={`old-col-${colIndex}`} style={TableCell}>{item[col]}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <table style={TableStyle}>
                            <thead>
                                {tableprops.map((item, index) => (
                                    <th key={index} style={TableCell}>{item}</th>
                                ))}
                            </thead>
                            <tbody>
                                {newV.map((item, rowIndex) => (
                                    <tr key={`new-row-${rowIndex}`}>
                                        {tableprops.map((col, colIndex) => (
                                            <td
                                                key={`new-col-${colIndex}`}
                                                style={IsDifferent(item[col], oldV[rowIndex][col]) ? { ...TableCell, ...IsDifferentStyle } : TableCell}
                                            >
                                                {item[col]}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                )
            default:
                return (
                    <div style={elements}>
                        <span>{oldV}</span>
                        <span
                            style={IsDifferent(oldV, newV) ? IsDifferentStyle : {}}
                        >
                            {newV}
                        </span>
                    </div>
                );
        }
    }

    return (
        <div style={wraperStyle}>
            <h2 style={{ marginBottom: "4px" }}>{title}</h2>
            {renderContent()}
        </div>
    )
}

VersionComparison.propTypes = {
    newV: PropTypes.any.isRequired,
    oldV: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["Link", "List", "Table"]),
    tableprops: PropTypes.array

};

export default VersionComparison;