import React, { useEffect, useState} from "react";
import TableRow from "./Row/TableRow";
import Loader from "../../components/Utils/Loader";
import styles from "../styles/TableUpload.module.css";
import { createProduct, prodExists } from "../../components/APIS/indexedDBUtils";
import { getClaimedEPD, GetCorrectedEPD } from "../../components/APIS/uploadAPI";
import { fetchAllCategories, fetchSubcategories } from "../../components/APIS/categoryAPI";
import SearchEngineID from "./SearchEngine/SearchEngineID";
import VersionTable from "./Version Control/VersionTable";
import RadioButtonsGroup from "../../components/radiobuttongroup";
import UploadComponent from "./Row/UploadRow";
import ProcessingComponent from "./Row/ProcessRow";
import { getProcessStatus } from "../../components/APIS/uploadAPI";
import { useSearchParams } from "react-router-dom";
import styles2 from "./Row/UploadRow.module.css";

function ProducentTable() {
    const [searchParams, setSearchParams] = useSearchParams();
    const initialRadioValue = searchParams.get('tap') || 'Hent EPD';
    const [radioValue, setRadioValue] = useState(initialRadioValue);

    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setSearchParams({ tap: radioValue });
    }, [radioValue]);





    const LoadClaimProducts = async () => {
        try {
            let data = await getClaimedEPD();

            if (!Array.isArray(data.products)) {
                // Handle different response types accordingly
                console.error('Unexpected data format:', data);
                throw new Error('Expected an array of products.');
            }
            // console.log(data);
            // const productIds = data.map((product) => product.id);
            // console.log(productIds);

            setProducts(data.products);

            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };


    const loadCorrectedProducts = async () => {
        try {
            let data = await GetCorrectedEPD();
    
            if (!data || !Array.isArray(data)) {
                console.error('Unexpected data format:', data);  // More informative logging
                throw new Error('Expected an object with a products array.');
            }
    
            setProducts(data.map((product) => (product)));
            setLoading(false);
        } catch (error) {
            console.error('Error in loadCorrectedProducts:', error);
            setError(error);
            setLoading(false);
        }
    };
    


    useEffect(() => {
        LoadClaimProducts();
    }, []);

    const checkProcessStatus = async (product) => {
        try {
            const status = await getProcessStatus(product.id);
            return {
                ...product,
                status: status,
            };
        } catch (error) {
            console.error('Error fetching product status:', error);
            return {
                ...product,
                status: 'Unknown Status',
            };
        }
    };


    const handleRadioChange = async (e) => {
        const selectedValue = e.target.value;


        setRadioValue(selectedValue);

        if (selectedValue === 'Processing') {
            setProducts([]);
            await LoadClaimProducts(); 
        }

        if (selectedValue === 'Review') {
            setProducts([]);
            try {
                // First, load the claimed products
                const productsData = await getClaimedEPD();

                // Check the statuses for all products
                const statusPromises = productsData.products.map(checkProcessStatus);

                const updatedProducts = await Promise.all(statusPromises);

                // Filter products with status !== 2
                 const filteredProducts = updatedProducts.filter(product => product.status !== 1);

                // Update the state with only products that don't have status 2
                setProducts(filteredProducts);
            } catch (error) {
                console.error('Error fetching product status:', error);
            }
        }

        if (selectedValue === 'Produkter') {
            setProducts([]);
                await loadCorrectedProducts();

        }
    };
    const handleFilesChange = async (productId, files) => {
        try {
            await createProduct(productId, files);
        } catch (error) {
            console.error('Error saving product data:', error);
        }
    }
    


    return (
        <div style={{ paddingBottom: '20px', paddingTop: '40px' }}>
            <div className={styles.thable}>
                <div className="searchbar-and-radio">
                    <RadioButtonsGroup
                        options={['Hent EPD', 'Upload', 'Processing', 'Review', 'Version Control', 'Produkter']}
                        selectedValue={radioValue}
                        handleRadioChange={handleRadioChange}
                    />
                </div>
                {radioValue === 'Hent EPD' ? (
                    <div>
                        <SearchEngineID />
                    </div>
                ) : radioValue === 'Upload' ? (
                    <div>
                        
                            <UploadComponent/>
                    
                        </div>
                ) : radioValue === 'Processing' ? (
                    <div>
                        {/* Add your processing content */}
                        <ProcessingComponent products={products} />
                    </div>
                ) : radioValue === 'Review' || radioValue === 'Produkter' ? (
                    <table className={styles2.uploadTable}>
                        <thead >
                        <tr>
                            <th>Ret / PDF Status</th>
                            <th>Gyldig Til</th>
                            <th>Navn</th>
                            <th>EPD ID</th>
                            {/* <th>RSL Værdi</th> */}
                            <th>Standard</th>
                            <th>Teknisk Specifikation</th>
                            <th>Enheder</th>
                            <th>Certificering</th>
                            <th>Andre Filer</th>
                        </tr>
                        </thead>
                        <tbody>
                            {products.map((product) => (
                                <TableRow
                                    key={product.id}
                                    data={product}
                                />
                            ))}
                        </tbody>
                    </table>
                ) : radioValue === 'Version Control' ? (
                    <div>
                        <VersionTable />
                    </div>
                ) : null}
            </div>
        </div>
    );
}



export default ProducentTable;