import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import VersionComparison from './components/VersionComparison'
import VersionAccept from './components/VersionAccept'


function ResolveVersionPage() {
    const location = useLocation();
    const navigate = useNavigate();

    const data = location.state;
    console.log(data);

    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "row", gap: "20vw" }}>
                    <h1>Old version</h1>
                    <h1>New version</h1>
                </div>
                <VersionComparison
                    oldV={data.version.oldVersion.version}
                    newV={data.version.newversion.version}
                    title={"Version"}
                />

                <VersionComparison
                    oldV={data.version.oldVersion.productName}
                    newV={data.version.newversion.productName}
                    title={"Product Name"}
                />

                <VersionComparison
                    oldV={data.version.oldVersion.producerName}
                    newV={data.version.newversion.producerName}
                    title={"Producer Name"}
                />

                <VersionComparison
                    newV={data.version.newversion.categories}
                    oldV={data.version.oldVersion.categories}
                    title={"Category"}
                    type={"List"}
                />
                <VersionComparison
                    oldV={data.version.oldVersion.subCategory}
                    newV={data.version.newversion.subCategory}
                    title={"Subcategory"}
                />

                <VersionComparison
                    oldV={data.version.oldVersion.declaredUnits}
                    newV={data.version.newversion.declaredUnits}
                    title={"Declared Unit"}
                    type={"Table"}
                    tableprops={["unit", "amount", "safetyLevel"]}
                />

                <VersionComparison
                    oldV={data.version.oldVersion.materials}
                    newV={data.version.newversion.materials}
                    title={"Materials"}
                    type={"Table"}
                    tableprops={["kg", "materialName", "percentage"]}
                />
                <VersionComparison
                    oldV={data.version.oldVersion.masseFylde}
                    newV={data.version.newversion.masseFylde}
                    title={"Massefylde"}
                    type={"Table"}
                    tableprops={["density", "thickness", "volume"]}
                />

                <VersionComparison
                    oldV={data.version.oldVersion.gwpValues}
                    newV={data.version.newversion.gwpValues}
                    title={"GWP Values"}
                    type={"Table"}
                    tableprops={["a1A3", "a5", "b3", "c1", "c2", "c3", "c4", "d", "gwpUnit"]}
                />

                <VersionComparison
                    oldV={data.version.oldVersion.compliance}
                    newV={data.version.newversion.compliance}
                    title={"Compliance"}
                    type={"List"}
                />

                <VersionComparison
                    oldV={data.version.oldVersion.pcr}
                    newV={data.version.newversion.pcr}
                    title={"PCR"}
                    type={"List"}
                />

                <VersionComparison
                    oldV={data.version.oldVersion.rsl}
                    newV={data.version.newversion.rsl}
                    title={"RSL"}
                    type={"Table"}
                    tableprops={["rslValue", "rslData"]}
                />

                <VersionComparison
                    oldV={data.version.oldVersion.link}
                    newV={data.version.newversion.link}
                    title={"EPD Link"}
                    type={"Link"}
                />

                <VersionComparison
                    oldV={data.version.oldVersion.validTo}
                    newV={data.version.newversion.validTo}
                    title={"Valid to"}
                />

            </div>
            <VersionAccept
                data={data.version}
            />
        </div>


    )
}

export default ResolveVersionPage;
