// import MenuProfile from "../components/menuProfile";
import React, { useState , useEffect } from 'react';
import { getprofileinfo } from '../components/APIS/ProfileAPI';
import MenuProfile from '../components/Utils/menuProfile';
import { useGlobalContext } from '../GlobalContext';


function Profile() {
    const { userType } = useGlobalContext();
    console.log(userType)
    
    
    return (
        <div>
            {/* <MenuProfile /> */}
            <div className="profileInfo">
                <h1>Profil</h1>

                <div className="profileInfoContent">
                    <div className="profileInfoContentLeft">

                        <h3>Navn: {userType.firstname} {userType.lastname}</h3>

                        <h3>Firma: {userType.companyName}</h3>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Profile;