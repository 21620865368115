import React, { useState, useEffect } from 'react';
import { getProcessStatus } from '../../../components/APIS/uploadAPI';
import styles from './UploadRow.module.css';
import Loader from '../../../components/Utils/Loader';
function ProcessingComponent({ products }) {
  const [productStatuses, setProductStatuses] = useState([]);
  const [loading, setLoading] = useState(false);
  // Status labels corresponding to status codes
  const statusLabels = {
    0: 'PENDING',
    1: 'PROCESSING',
    2: 'PROCESSED',
    3: 'FAILED',
    4: 'NO PROCESSING REQUIRED',
    5: 'No Documents Found',
  };

  // Function to fetch the statuses
  const fetchStatuses = async () => {
    setLoading(true);
    try {
      // Use Promise.all to fetch statuses in parallel
      const statusPromises = products.map(async (product) => {
        const statusCode = await getProcessStatus(product.id);
        return {
          ...product,
          status: statusLabels[statusCode],
        };
      });

      const updatedProducts = await Promise.all(statusPromises);
      setProductStatuses(updatedProducts);
    //   console.log("checking")
  } catch (error) {
    console.error('Error fetching product statuses:', error);
  }
  setLoading(false);
  };

  // Fetch statuses every second
  useEffect(() => {
    if (products && products.length > 0) {
      // Create an interval that calls fetchStatuses every second
      const intervalId = setInterval(fetchStatuses, 1000);

      // Cleanup: Clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [products]); 



  return (
    <div>
      <table className={styles.uploadTable}>
        <thead>
          <tr>
            <th>Product Name</th>
            <th>EPD ID</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
         

          {productStatuses.map((product) => (
            <tr key={product.id}>
              <td>{product.productName}</td>
              <td>{product.epdid}</td>
              <td>{product.status || 'Unknown Status'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ProcessingComponent;
