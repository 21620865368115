import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { CorrectProduct } from "../../components/APIS/uploadAPI";
import StyledButton from "../../components/StyledButton";
import CorrectForm from "./CorrectComponents/CorrectFormComponent";
import Loader from "../../components/Utils/Loader";
import { AiFillAccountBook, AiOutlineFilePdf } from "react-icons/ai";


function Correctpage() {

    const categories = ["facade", "fundament", "gulv", "inderveg", "isolering", "konstruktion", "loft", "prefabrikeredeElementer", "tag"];
    const Subcategories = [
        "Træ og træbaserede plader", "Plast og gummi", "Andre minerale materialer", "Natursten, tagsten, glas, keramik", "Maling og overfladebehandling", "System", "Standardværdier", "Rør", "Færdige elementer", "Metaller", "Andre biogene materialer", "Affald", "Jordarter, drænmateriale"]

    const location = useLocation();
    const navigate = useNavigate();

    const { data, version } = location.state;
    const [formData, setFormData] = useState({
        
        categories: [],
        subCategory: "",
        compliance: [],
        materials: [],
        declaredUnits: [],
        masseFylde: [],
        pcr: [],
        rsl: [],
        buyLink: "",
        brand: [],
        tekniskSpecifikation: []

    });

    const [Loading, setLoading] = useState(false);

    function parseDensity(densityString) {
        // Updated regular expression to capture the entire last unit part
        const matches = densityString.match(/^(\d+)\s*(?:kg|ton|m³|m²|m|stk)?\s*(?:\/\s*(kg|ton|m³|m²|m|stk))?$/);

        if (matches) {
            const value = matches[1]; // First group is the numeric part
            const unit = matches[2] || ""; // Last group is the final unit or empty if not present
            return { value, unit };
        }

        return { value: densityString, unit: "" }; // Default in case of no match
    }
    function parseVolume (volumeString) {
         // Updated regular expression to capture the entire last unit part
         const matches = volumeString.match(/^(\d+)\s*(kg|ton|m³|m²|m|stk)?$/);

         if (matches) {
             const value = matches[1]; // First group is the numeric part
             const unit = matches[2] || ""; // Last group is the final unit or empty if not present
             return { value, unit };
         }
 
         return { value: volumeString, unit: "" }; // Default in case of no match
    }

    const [MasseFylde, setMasseFylde] = useState({
        volume: data?.masseFylde?.[0]?.volume ? parseVolume(data.masseFylde[0].volume) : null,
        weight: data?.masseFylde?.[0]?.weight || null,
        thickness: data?.masseFylde?.[0]?.thickness || null,
        density: data?.masseFylde?.[0]?.density ? parseDensity(data.masseFylde[0].density) : null,
    });
    useEffect(() => {
        if (location.state) {
            console.log(data);
            setFormData({
                categories: data.categories || [],
                subCategory: data.subCategory || "",
                compliance: data.compliance || [],
                materials: data.materials || [],
                declaredUnits: data.declaredUnits || [],
                masseFylde: MasseFylde || [],
                pcr: data.pcr || [],
                rsl: data.rsl || [],
                buyLink: data.buyLink || null,
                brand: data.producentUploadData.brand || [],
                tekniskSpecifikation: data.producentUploadData.tekniskSpecifikation || []
            });
        }
    }, []);

    const handleAddField = (dataName, fields, isObject) => {
        setFormData(prevFormData => {
            const updatedData = { ...prevFormData };
    
            //special case for if declared unit is empty and we need to add new field
            if (dataName === "declaredUnits") {
                // Check if declaredUnits is already initialized and if it's empty
                const newDeclaredUnit = {
                    isInitial: true,
                    ...fields.reduce((acc, field) => ({ ...acc, [field]: "" }), {})
                };
                updatedData[dataName] = [...prevFormData[dataName], newDeclaredUnit];
            } else if (isObject) {
                // Create an object with empty values for other fields
                const newObject = fields.reduce((acc, field) => ({ ...acc, [field]: "" }), {});
                updatedData[dataName] = [...prevFormData[dataName], newObject];
            } else {
                // For simple fields, add an empty string to the array
                updatedData[dataName] = [...prevFormData[dataName], ""];
            }
    
            return updatedData;
        });
    };
    

    const handleRemoveField = (dataName, index, isObject) => {
        setFormData(prevFormData => {
            const updatedData = { ...prevFormData };
    
            // Check if the field exists and has items to remove
            if (Array.isArray(updatedData[dataName]) && updatedData[dataName].length > index) {
                if (isObject) {
                    // Remove the object at the specified index from the array
                    updatedData[dataName] = updatedData[dataName].filter((_, i) => i !== index);
                } else {
                    // Remove the string at the specified index from the array
                    updatedData[dataName] = updatedData[dataName].filter((_, i) => i !== index);
                }
            }
    
            return updatedData;
        });
    };
    
    const handleFormChange = (event, prop, index = null) => {
        const { name, value } = event.target;
        let updatedFormData = { ...formData };

        if (index !== null && Array.isArray(updatedFormData[prop])) {
            // Update array item at specific index (like materials)
            const updatedArray = [...updatedFormData[prop]];
            updatedArray[index][name] = value;
            updatedFormData[prop] = updatedArray;
        } else {
            // Update property directly if it's not an array
            updatedFormData[name] = value;
        }

        setFormData(updatedFormData);
    };

    const handleFormArrayField = (fieldName, index, value) => {
        // Create a shallow copy of the array at formData[fieldName]
        const updatedArray = [...formData[fieldName]];

        // Update the specific index with the new value
        updatedArray[index] = value;

        // Set the updated formData
        setFormData({
            ...formData,
            [fieldName]: updatedArray
        });
    };

    const handleMassefyldeChange = (event, dataName = null) => {
        const { name, value } = event.target;
        setMasseFylde((prev) => {
            const updatedMasseFylde = { ...prev };
    
            // Update specific fields based on the input's name
            if (name === "value") {
                updatedMasseFylde[dataName] = { ...updatedMasseFylde[dataName], value: value };
            } else if (name === "unit") {
                updatedMasseFylde[dataName] = { ...updatedMasseFylde[dataName], unit: value };
            } else {
                updatedMasseFylde[name] = value;
            }
    
            return updatedMasseFylde;
        });
    };

    const handleCategoryChange = (event, category) => {
        const { checked } = event.target;
        let updatedCategories = [...formData.categories];  // Clone the current categories

        if (checked) {
            // Add the category if checked
            updatedCategories.push(category);
        } else {
            // Remove the category if unchecked
            updatedCategories = updatedCategories.filter(cat => cat !== category);
        }

        setFormData({
            ...formData,
            categories: updatedCategories
        });
    };

    const handleSubcategoryChange = (event, subCategory) => {
        const { checked } = event.target;

        setFormData({
            ...formData,
            subCategory: checked ? subCategory : null  // Set subcategory or clear it
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const InitialDeclaredUnit = {
            unit: formData.declaredUnits[0].unit,
            amount: formData.declaredUnits[0].amount
        }
        const producentUpload = {
            Brand: formData.brand,
            Certifications: data.producentUploadData.certifications.map(item => item.certification),   
            ImageId: data.producentUploadData.imageURLS.map(item => item.fileId),
            ProducentBlad: data.producentUploadData.producentBlad,
            TekniskSpecifikation: formData.tekniskSpecifikation,
            Other: data.producentUploadData.otherURLS
        }
        const masseFylde =[{
            volume: MasseFylde.volume.value + " " + MasseFylde.volume.unit,
            density: MasseFylde.density.value + " kg/" + MasseFylde.density.unit,
            thickness: MasseFylde.thickness,
            weight: MasseFylde.weight
        }]

        const ProductCorrectionRequest = {
            Id: data.id,
            BuyLink: formData.buyLink,
            Categories: formData.categories,
            SubCategory: formData.subCategory,
            Materials: formData.materials,
            RSL: formData.rsl,
            PCR: formData.pcr,
            MasseFylde: masseFylde,
            ProducentUpload: producentUpload,
            Compliance: formData.compliance,
            InitialDeclaredUnit: InitialDeclaredUnit,
            NewVersion: version
        }
        console.log(ProductCorrectionRequest);
        try {
            setLoading(true);
            // console.log(ProductCorrectionRequest);
            let data = await CorrectProduct(ProductCorrectionRequest);
            navigate('/produkter?tap=Review');
            return data;
        }
        catch (error) {
            console.error("there was an error correcting data", error);
            return null;
        }
    };
    // console.log(data)

    return (
        <form onSubmit={handleSubmit}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", flexWrap: "wrap" }}>
                <div>
                    <h1 style={{color:"var(--Green)",textAlign:"center"}}>Ret Produkt</h1>
                        <h2 style={{color:"var(--Black)",fontSize:"16px"}}>{data.productName}</h2>
                    
                            <div style={{display:"flex", flexDirection:"row"}}>
                                <a href={data.link} target="_blank" style={{display: "flex", alignItems: "center", textDecoration: "none"}}>
                                    <AiOutlineFilePdf style={{color:"red", cursor:"pointer"}} size={22} />
                                    <p style={{color:"var(--Black)", fontSize:"16px", cursor:"pointer", marginLeft:"8px"}}>{data.epdid}</p>
                                </a>
                
                        </div>


                </div>

                <CorrectForm type={"Category"} title={"Kategorier"} data={formData.categories} onchange={handleCategoryChange} categories={categories} />

                <CorrectForm type={"Category"} title={"Sub Kategorier"} data={formData.subCategory} onchange={handleSubcategoryChange} categories={Subcategories} />

                <CorrectForm type={"Object"} data={formData.materials} title={"Materialer"} onchange={handleFormChange} dataName={"materials"} input={["materialName", "kg", "percentage"]} addField={handleAddField} removeField={handleRemoveField}/>

                <CorrectForm type={"List"} data={formData.pcr} title={"PCR"} onchange={handleFormArrayField} dataName={"pcr"} addField={handleAddField} removeField={handleRemoveField}/>

                <CorrectForm type={"Object"} data={formData.rsl} title={"RSL"} onchange={handleFormChange} dataName={"rsl"} addField={handleAddField} input={["rslValue", "rslData"]} removeField={handleRemoveField}/>

                <CorrectForm type={"Massefylde"} data={MasseFylde} title={"Massefylde"} onchange={handleMassefyldeChange} dataName={"masseFylde"} addField={handleAddField}/>

                <CorrectForm type={"List"} data={formData.compliance} title={"Compliance"} onchange={handleFormArrayField} dataName={"compliance"} addField={handleAddField} removeField={handleRemoveField}/>

                <CorrectForm type={"Unit"} data={formData.declaredUnits.filter(unit => unit.isInitial == true)} title={"Units"} onchange={handleFormChange} dataName={"declaredUnits"} input={["amount" ,"unit"]} addField={handleAddField}/>

                <CorrectForm type={"Unit"} data={formData.brand} title={"Brand"} onchange={handleFormChange} dataName={"brand"} input={["value" ,"unit"]} addField={handleAddField} removeField={handleRemoveField}/>

                <CorrectForm type={"Unit"} data={formData.tekniskSpecifikation} title={"TekniskSpecifikation"} onchange={handleFormChange} dataName={"tekniskSpecifikation"} input={["parameter" ,"value", "unit", "standard"]} addField={handleAddField} removeField={handleRemoveField}/>

                <CorrectForm type={"BuyLink"} data={formData.buyLink} title={"Buy Link"} onchange={handleFormChange} dataName={"buyLink"}/>

                {!Loading ? (<StyledButton type="submit" title={"Godkend"}></StyledButton>) : (<Loader sizex={50} />)}
            </div>
        </form>
    )
}
export default Correctpage; 