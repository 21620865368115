import { baseUrl } from "../../GlobalContext";


export const postNewProject = async (ProjectName) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
  };
  
  const bodyName = JSON.stringify({ ProjectName: ProjectName });

  try { 
    const response = await fetch(`${baseUrl}/Project`, {
      method: 'POST',
      headers: headers,
      body: bodyName
    });

    if (!response.ok) {
      return { success: false, message: (await response.json()).error || response.statusText };
    }

    return { success: true, message: 'Project created and updated successfully.' };

  } catch (error) {
    return { success: false, message: error.message || 'An error occurred during the request.' };
  }
};


export const AddProductToProject = async (projectId, productId, category) => {
  const header = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`,
  };

  try {
    const response = await fetch(`${baseUrl}/Project/${projectId}?productId=${productId}&category=${category}`, {
      method: 'POST',
      headers: header,
    });

    if (response.ok) {
      return { success: true, message: 'Product successfully added to project.' };
    } else {
      // Check if the response is JSON or text
      const contentType = response.headers.get('Content-Type');
      let responseBody;
      
      if (contentType && contentType.includes('application/json')) {
        responseBody = await response.json(); // Parse JSON if content is JSON
      } else {
        responseBody = await response.text(); // Fallback to plain text
      }

      return { success: false, message: responseBody.message || responseBody || response.statusText };
    }
  } catch (error) {
    console.error('Error posting:', error);
    return { success: false, message: error.message || 'Error posting project' };
  }
};





export const getProjects = async () => {
  try {
    const response = await fetch(`${baseUrl}/Project`, {
      method: 'GET',
      headers: {

        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
        }
        });
    if (!response.ok) {
      throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
    }

    let data = await response.json();
    data = data.map(project => ({
      id: project.id,
      ProjectName: project.projectName,
      items: project.productIds,
    }));

    return data;
  } catch (error) {
    console.error(`Error fetching projects:`, error);
    return [];
  }
};

export const getOneProjectbyId = async (id) => {
  const header = {'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
          };

  try { const response = await fetch(`${baseUrl}/Project/${id}`, {
    method: 'GET',
    headers: header
  });
  if (!response.ok) {
    throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
  }
  const data = await response.json();
  // console.log(`Data received:`, data);
  return data;
}
catch (error) {
  console.error(`Error fetching projects:`, error);
  return [];
}
};






export const deleteProject = async (id) => {
  const header = {'Content-Type': 'application/json',
         'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
        };
  try {
    const response = await fetch(`${baseUrl}/Project/${id}`, {
      method: 'DELETE',
      headers: header
    });

    if(response.ok) {
      return { success: true, message: 'Project deleted successfully.' };
    } else {
      throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
    }
    
  } catch (error) {
    console.error("Could not delete project:", error);
    
  }
      
};

export const deleteFromProject = async (ProjectId, ProductId, category) => {

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
  };

  try {
    const response = await fetch(`${baseUrl}/Project/${ProjectId}/product?productId=${ProductId}&category=${category}`, {
      method: 'DELETE',
      headers: headers,
    });

    // Check if the response is OK, which indicates a successful operation
    if (response.ok) {


      return { success: true, message: 'Product removed from project successfully.' };
    }

    // Handle error cases like 400 or 500 status codes
    if (response.status === 400 || response.status === 500) {
      const responseBody = await response.json();
      return { success: false, message: responseBody.message || 'An error occurred while trying to remove the product.' };
    }

    // Handle other non-OK responses
    return { success: false, message: 'An error occurred. The server did not process the request.' };

  } catch (error) {
    console.error("Could not delete project:", error);
    return { success: false, message: error.message || 'An error occurred during the request.' };
  }
};










