import React from 'react';
import PropTypes from 'prop-types';
import styles from './safetylevel.module.css';

const safetyDescriptions = {
  0: "Level 0 Calculated with AI",
  1: "Level 1 Initial Values",
  2: "Level 2 Verified by producers",
  3: "Level 3 Buildtivity and producer verified",
};

const SafetyLevelIcon = ({ safetyLevel},size,) => {
  // Ensure the safety level is within expected range (0-4)
  const validSafetyLevel = safetyLevel >= 0 && safetyLevel <= 3 ? safetyLevel : 0;
  
  // Path to the SVG based on safety level
  const iconSrc = `${process.env.PUBLIC_URL}/safetyLevel/Verificering Ikoner_Level ${validSafetyLevel}.svg`;

  return (
    <div className={styles.safetyLevelIcon}>
      {/* Display the SVG image */}
      <img 
        src={iconSrc} 
        alt={`Safety level ${validSafetyLevel}`}
        width={size}

        aria-labelledby={`safety-tooltip-${validSafetyLevel}`} 
      />
      
      {/* Tooltip with safety level description */}
      <div className={styles.tooltip} role="tooltip" id={`safety-tooltip-${validSafetyLevel}`}>
        {safetyDescriptions[validSafetyLevel]}
      </div>
    </div>
  );
};

// Define prop types for type-checking
SafetyLevelIcon.propTypes = {
  safetyLevel: PropTypes.number.isRequired
};

export default SafetyLevelIcon;
