import React, { useState } from 'react';
import { FeedbackAPI } from './APIS/FeedbackAPI';
import './FeedbackModal.css';

const FeedbackModal = ({ closeModal }) => {
  const [title, setTitle] = useState('');
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [thankYouMessage, setThankYouMessage] = useState(''); // State for thank you message

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setThankYouMessage('Thank you for your feedback!'); // Always set thank you message

    try {
      await FeedbackAPI(title, feedback);
    } catch {
      // Ignoring errors intentionally
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        closeModal();
      }, 2000); // Close modal after a short delay
    }
  };

  // Close modal when clicking outside the content
  const handleOverlayClick = () => {
    closeModal();
  };

  // Prevent closing when clicking inside the content
  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="feedback-modal-overlay" onClick={handleOverlayClick}>
      <div className="feedback-modal-content" onClick={handleContentClick}>
        <h2>Submit Feedback</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Title:
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </label>
          <label>
            Feedback:
            <textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              required
            ></textarea>
          </label>
          <div className="modal-buttons">
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
            <button type="button" onClick={closeModal} disabled={isSubmitting}>
              Cancel
            </button>
          </div>
        </form>
        {thankYouMessage && <p className="thank-you-message">{thankYouMessage}</p>}
      </div>
    </div>
  );
};

export default FeedbackModal;
