

function UnitTranslator (unit) {


        const unitMap = {
            "kg CO2 eqv.": ["kg CO2 eqv.", "kg CO2-eqv.", "kg CO_(2) eq", "kg CO_(2) eqv.", "kg CO2-Äqv.", "kg CO_(2)-Äq."],
            "kg CFC11 eqv.": ["kg CFC11 eq", "kg CFC11 eqv.", "kg CFC 11 eqv.", "kg CFC 11-Äquiv.", "kg CFC11-Äq.", "kg CFC 11 eq."],
            "kg PO4 eqv.": ["kg PO4 eq", "kg PO_(4)^(3) eq", "kg PO_(4)^(3)-Äq.", "kg Phosphate-eqv.", "kg Phosphat-Äqv.", "kg P eqv.", "kg P eq", "kg P-Äq."],
            "mol H+ eqv.": ["mol H+ eqv.", "mol H^(+) eqv.", "mol H^(+)-Äq.", "mol H+ eq"],
            "m³ world eqv. deprived": ["m³ world eqv. deprived", "m³ world eqv.", "m³ world-Äq. deprived", "m3 world eqv.", "m³ Welt-Äq. entzogen"],
            "kg NMVOC eqv.": ["kg NMVOC eq.", "kg NMVOC eqv.", "kg NMVOC-Äq."],
            "kg N eqv.": ["kg N eq.", "kg N eqv.", "kg N-Äq."],
            "CTUh": ["CTUh", "CTUe", "disease incidence", "Krankheitsfälle", "Disease incidence"],
            "kg": ["kg"],
            "MJ": ["MJ"],
            "SQP": ["SQP"],
            "m³": ["m3", "m³","m^(3)", "m^3"],
            "m²": ["m2", "m²", "m^(2)", "m^2"],
            "kg Sb eqv.": ["kg Sb-Äqv.", "kg Sb eq", "kg Sb-Äq.","kg Sb-Äqv."],	
            "kg Ethen eqv." : ["kg Ethen eqv.", "kg Ethen-Äqv.", "kg Ethen-Äq.", "kg Ethen eq."],
            "kg SO2 eqv." : ["kg SO2 eqv.", "kg SO2-Äqv.", "kg SO2-Äq.", "kg SO2 eq."],
            "kg PO4 eqv." : ["kg PO4 eqv.", "kg PO4-Äqv.", "kg PO4-Äq.", "kg PO4 eq."],
            
          };
    
    

    
      // Normalize the input unit
      for (const [correctUnit, variations] of Object.entries(unitMap)) {
        if (variations.includes(unit)) {
          return correctUnit;
        }
      }
      
      return unit;
    }
export default UnitTranslator;

