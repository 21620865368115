import React from 'react';
import Team from './Ompag/Team.js';
import BottomSection from './Ompag/BottomSection.js';
import './styles/OmPage.css';

function OmPage() {
  
    const paragraphs = [
      "Buildtivity er byggebranches svar på den grønne omstilling. Det er en platform, som overskueliggør materialernes data og dermed materialevalget i byggebranchen. Hertil er platformen udviklet i samarbejde med virksomheder i branchen som Lendager Group, Kingspan, Knauf, m.m. Dette er blandt andet med til at sikre en effektiv kommunikation mellem vores 3 primære kundegrupper: Arkitekter, Ingeniører og Entreprenører.",
  
      "Buildtivity er et stærkt team på 4 personer bestående af Iben, Simon, Max og Tobias. Simon og Iben står for virksomheds- og produktudvikling, marketing samt kundekontakt. Max og Tobias udvikler koden til platformen med hver deres kompetence. Max står for backend, Tobias for frontend.",
  
  ];

  
    const imagePath = [
      {id:1,path: "/images/omOs/IMG1.jpg"},
      {id:2,path: "/images/omOs/IMG2.jpg"},
      {id:3,path: "/images/omOs/IMG3.jpg"},

    ]

return (
  <div className="OmPage__container">
      <div className="OmPage__twoColumnLayout">
        <h1 className="OmPage__header">Buildtivity</h1>
        <div className="OmPage__imgRow">
          {imagePath.map((image, index) => (
            <img  key={index} src={image.path} alt={`Image ${image.id}`} className="OmPage__image" />
          ))}
        </div>
          {/* <div className="OmPage__textColm">
            {/* {paragraphs.map((paragraph, index) => (
                    <p key={index} className='OmPage__text'>
                        {paragraph}
                    </p>
                    ))} 
          </div>
          <div className="OmPage__imgColm">
              {imagePath.map((image, index) => (
                 <img key={index} src={image.path} alt={`Image ${image.id}`} className="OmPage__image" />   
              ))}
          </div> */}
      </div>
      <Team />
      <BottomSection />
  </div>
);
}

export default OmPage;
