import React, { useState, useEffect } from 'react';
import { CiSquarePlus } from 'react-icons/ci';
import { FaPlus } from 'react-icons/fa';
import { ImCheckmark } from 'react-icons/im';
import {
  postNewProject,
  deleteFromProject,
  getProjects,
  AddProductToProject,
} from '../APIS/postGetProject';

// Import the CSS module
import styles from '../../pages/styles/DropdownAndModal.module.css';

export const DropdownAndModal = ({ ItemId, category }) => {
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');
  const [hoverCreateNewProject, setHoverCreateNewProject] = useState(false);
  const [clickCreateNewProject, setClickCreateNewProject] = useState(false);

  // Fetch projects once on component mount
  useEffect(() => {
    const fetchProjects = async () => {
      const projects = await getProjects();
      setSelectedProjects(projects || []);
    };
    fetchProjects();
  }, []);

  const refreshProjectsList = async () => {
    try {
      const updatedProjects = await getProjects();
      console.log('Updated projects:', updatedProjects);
      if (updatedProjects) setSelectedProjects(updatedProjects);
    } catch (error) {
      console.error('Failed to fetch projects:', error);
    }
  };

  const handleAddToProject = async (projectId) => {
    const response = await AddProductToProject(projectId, ItemId, category);
    if (response.success) {
      await refreshProjectsList();
    } else {
      console.error('Add to project failed:', response.message);
    }
  };

  const handleRemoveFromProject = async (projectId) => {
    const response = await deleteFromProject(projectId, ItemId, category);
    if (response.success) {
      await refreshProjectsList();
    } else {
      console.error('Remove from project failed:', response.message);
    }
  };

  const handleCloseModal = async () => {
    setShowModal(false);
    setNewProjectName('');
    setClickCreateNewProject(false);
    await refreshProjectsList();
  };

  const handleProjectNameChange = (e) => {
    setNewProjectName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await postNewProject(newProjectName);
    if (response.success) {
      await refreshProjectsList();
      const currentProject = selectedProjects.find(
        (project) => project.ProjectName === newProjectName
      );
      if (currentProject) await handleAddToProject(currentProject.id);
    } else {
      console.error('Failed to create new project:', response.message);
    }
    handleCloseModal();
  };

  const openModal = async () => {
    await refreshProjectsList();
    setShowModal(true);
  };

  return (
    <>
      <div className={styles.flexContainer}>
        <CiSquarePlus
          size={32}
          onClick={(e) => {
            openModal();
            e.stopPropagation();
          }}
          className={styles.modalTrigger}
        />
      </div>

      {showModal && (
        <div className={styles.modalBackground} onClick={handleCloseModal}>
          <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
            <div
              className={`${styles.projectCreationContainer} ${
                hoverCreateNewProject ? styles.projectCreationContainerHover : ''
              }`}
              onMouseEnter={() => setHoverCreateNewProject(true)}
              onMouseLeave={() => setHoverCreateNewProject(false)}
              onClick={() => setClickCreateNewProject(true)}
            >
              {clickCreateNewProject ? (
                <div>
                  <h2 className={styles.projectTitle}>Create New Project</h2>
                  <form onSubmit={handleSubmit}>
                    <label className={styles.margin10px}>Project Name</label>
                    <input
                      type="text"
                      value={newProjectName}
                      onChange={handleProjectNameChange}
                    />
                    <button type="submit" className={styles.margin10px}>
                      Add New Project
                    </button>
                  </form>
                </div>
              ) : (
                <h2 className={styles.projectTitle}>Create New Project</h2>
              )}
            </div>
            <div className={styles.projectListContainer}>
              <h2 className={styles.projectAddTitle}>Add to Project</h2>
              {selectedProjects.length > 0 ? (
                <ul className={styles.projectList}>
                  {selectedProjects.map((project, index) => {
                    const isItemSelected =
                      project.items &&
                      project.items.some((item) => item.id === ItemId);
                    return (
                      <li
                        key={index}
                        className={styles.projectItem}
                        onClick={async (e) => {
                          e.stopPropagation();
                          if (isItemSelected) {
                            await handleRemoveFromProject(project.id);
                          } else {
                            await handleAddToProject(project.id);
                          }
                        }}
                      >
                        {isItemSelected ? (
                          <ImCheckmark
                            size={14}
                            className={styles.projectCheckmark}
                          />
                        ) : (
                          <FaPlus size={14} className={styles.projectPlus} />
                        )}
                        {project.ProjectName}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <div className={styles.noProjects}>No Projects</div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};


// {!createOnlyMode && (
//     <div style={{ width: '45%', height: '100%', paddingLeft: '20px', display: 'flex', flexDirection: 'column' }}>

//         <h1>{itemtitle}</h1>
//         <h2 style={{ color: colorCodes.Green }}>Add to Project</h2>
//         <ul style={{ margin: 0, padding: 0, height: 'calc(100% - 40px)', overflowY: 'auto' }}>
//             {selectedProjects.length === 0 ? (
//                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
//                     No Projects
//                 </div>
//             ) : (
//                 selectedProjects.map((project, index) => (
//                     <li key={index} style={{ cursor: 'pointer', background: hoveredProject === project.Project_name ? colorCodes.Grey : colorCodes.White, transition: 'background 0.3s', padding: '10px', display: 'flex', alignItems: 'center'
//                         }}
//                         onMouseEnter={() => setHoveredProject(project.Project_name)}
//                         onMouseLeave={() => setHoveredProject(null)}
//                         onClick={() => {
//                             if (!project.items || !project.items.some(item => item.itemTitle === title)) {
//                                 handleAddToProject(project.Project_name);
//                             }
//                         }}
//                     >
//                         {project.items && project.items.some(item => item.itemTitle === title) ? (
//                                 <>
//                                     <ImCheckmark size={14} style={{ marginRight: '5px', color: colorCodes.Green }} />
//                                     {/* the project.projcetid and project.itemtitle are both undefined?? */}
//                                     <button onClick={() => handleRemoveFromProject(project.Project_name)}>Remove</button>
//                                 </>)
//                                 :
//                                 <FaPlus size={14} style={{ marginRight: '5px', color: colorCodes.Black }} onClick={() => handleAddToProject(project.ProjectId)} />
//                         }
//                         {project.Project_name}
//                     </li>
//                 ))
//             )}
//         </ul>
//     </div>
// )}