import { baseUrl } from "../../GlobalContext";

export const FeedbackAPI = async (title, feedback) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
  };

  const body = JSON.stringify({
    Title: title,
    Feedback: feedback
  });

  try { 
    const response = await fetch(`${baseUrl}/Feedback/userfeedback`, {
      method: 'PUT',
      headers: headers,
      body: body
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    return { success: true, data: result };

  } catch (error) {
    return { success: false, message: error.message || 'An error occurred during the request.' };
  }
};
