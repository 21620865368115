import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './UploadRow.module.css';
import { GrTrash } from 'react-icons/gr';
import { deleteFile } from '../../../components/APIS/uploadAPI';
import CertificationModal from './CertificationModal';

const certificationsList = {
    1: 'CE',
    2: 'Svanemarke',
    3: 'Cradle to Cradle',
    4: 'Indeklima Mark',
    5: 'FSC',
    6: 'PEFC',
    7: 'EU Ecolabel',
    8: 'Der Blue Engel',
    9: 'Astma Mark',
    10: 'Allergy UK',
  };

function ProductRow({ product, onFilesChange, reloadData }) {
    const [files, setFiles] = useState({});
    const [showModal, setShowModal] = useState(false);
  
    // Map file types to endpoints
    const endpointMapping = {
      image: 'Pdf/image',
      'technical-specification': 'Pdf/technical-specification',
      BrugsBlad: 'Pdf/BrugsBlad',
      other: 'Pdf/Other',
      certifications: 'Pdf/certification',
    };
    useEffect(() => {
      const initialFiles = {
        image: product.producentUploadData?.imageURLS || [],
        'technical-specification': product.producentUploadData?.tekniskSpecifikationURL || null,
        BrugsBlad: product.producentUploadData?.producentBladURL || null,
        other: product.producentUploadData?.otherURLS || [],
        certifications: product.producentUploadData?.certifications || {},
      };
      setFiles(initialFiles);
    }, [product.producentUploadData]);
    
   const handleFileChange = (fileData, fileType) => {
    const updatedFiles = { ...files };
  
    if (fileType === 'certifications') {
      updatedFiles[fileType] = { ...fileData };
    } else if (fileType === 'image' || fileType === 'other') {
      // For file types that can have multiple files
      const existingFiles = files[fileType] || [];
      updatedFiles[fileType] = [...existingFiles, fileData];
    } else {
      // For single file types
      updatedFiles[fileType] = fileData;
    }
  
    setFiles(updatedFiles);
    onFilesChange(product.id, updatedFiles);
  };
  
  
  
  
  
  const handleDeleteFile = async (fileType, index = 0, certId = null, fileId) => {
    if (fileType === 'certifications' && certId) {
  
      if (files[fileType] && files[fileType][certId] instanceof File) {
        const updatedFiles = { ...files };
        delete updatedFiles[fileType][certId];
        setFiles(updatedFiles);
        onFilesChange(product.id, updatedFiles);
      }
  
      try {
        const endpoint = endpointMapping[fileType];
        const additionalParams = { certificationType: certId };
        await deleteFile(product.id, endpoint, additionalParams);
        setTimeout(async () => await reloadData(), 50);
      }
      catch (error) {
        console.error(`Error deleting ${fileType} for product ${product.id}:`, error
        );
      }
  
  
  
    } else if (fileType === 'image' || fileType === 'other') {
      const updatedFilesArray = [...(files[fileType] || [])];
      const fileToDelete = updatedFilesArray[index];
  
      // Remove the file from state
      updatedFilesArray.splice(index, 1);
      const updatedFiles = { ...files, [fileType]: updatedFilesArray };
      setFiles(updatedFiles);
      onFilesChange(product.id, updatedFiles);
  
      // If the file is hosted, delete it from the server
  
  
      if (!(fileToDelete instanceof File)) {
        try {
          const endpoint = endpointMapping[fileType];
          const fileId = product.producentUploadData?.[`${fileType}URLS`][index]?.fileId;
          if (!fileId) {
            console.error('File ID not found');
            return;
          }
          const additionalParams = { fileId };
          // console.log("product :", product.id, endpoint, additionalParams);
          await deleteFile(product.id, endpoint, additionalParams);
          await reloadData();
        } catch (error) {
          console.error(`Error deleting ${fileType} for product ${product.id}:`, error);
        }
      }
    } else {
  
      if (files[fileType] && files[fileType][index] instanceof File) {
  
        // If it's a local file, remove it from the state
        const updatedFiles = { ...files };
        delete updatedFiles[fileType];
        setFiles(updatedFiles);
        onFilesChange(product.id, updatedFiles);
      } else {
        try {
          const endpoint = endpointMapping[fileType];
          let additionalParams = {};
  
          if (fileType === 'image' || fileType === 'other') {
  
            const fileId = product.producentUploadData?.[`${fileType}URLS`][index]?.fileId;
            if (!fileId) {
              console.error('File ID not found');
              return;
            }
            additionalParams = { fileId };
          }
          await deleteFile(product.id, endpoint, additionalParams);
          setTimeout(async () => await reloadData(), 50);
        } catch (error) {
          console.error(`Error deleting ${fileType} for product ${product.id}:`, error);
        }
      }
    }
  };
  
  
  
  
  
  
  
    const renderFileField = (fileType, hostedData) => {
      const fileData = files[fileType] || [];
    
      // Handle certifications separately
      if (fileType === 'certifications') {
        return renderCertifications();
      }
    
      // If fileData is not an array (for single file types), convert it to an array
      const filesArray = Array.isArray(fileData) ? fileData : [fileData];
    
      // Render each file (local or hosted)
      if (filesArray.length > 0) {
        return filesArray.map((file, index) => {
          if (file instanceof File) {
            // Render local file
            return (
              <div key={`${fileType}-local-${index}`} className={styles.fileContainer}>
                <span>{file.name}</span>
                <GrTrash
                  onClick={() => handleDeleteFile(fileType, index)}
                  className={styles.fileIconDelete}
                />
              </div>
            );
          } else {
            // Render hosted file
            const url = typeof file === 'string' ? file : file.url;
            return renderFileLink(url, fileType, index);
          }
        });
      }
      return (
        <FileDropzone
          fileType={fileType}
          onFileUpload={(file) => handleFileChange(file, fileType)}
        />
      );
    };
    
  // Render individual file link
  const renderFileLink = (url, fileType, index = 0) => (
    <div key={`${fileType}-${index}`} className={styles.fileContainer}>
      <a href={url} target="_blank" rel="noopener noreferrer" className={styles.fileLink}>
        View File
      </a>
      <GrTrash
        onClick={() => handleDeleteFile(fileType, index)}
        className={styles.fileIconDelete}
      />
    </div>
  );
  const renderCertifications = () => {
    const certificationsData = files['certifications'] || {};
    // Get an array of certIds
    const uploadedCertifications = Object.keys(certificationsData);
    // console.log("uploadedCertifications :", uploadedCertifications);
    if (uploadedCertifications.length === 0) {
      return <div>No certifications uploaded.</div>;
    }
  
    return uploadedCertifications.map((certId, index) => {
      const certFile = certificationsData[certId];
      const url = certFile.url || '';
      let id = certificationsData[certId].certification;
      if (id === undefined) {
        id = certId;
      }
  
  
  
      return (
        <div key={`cert-${index}`} className={styles.fileContainer}>
          <a href={url} target="_blank" rel="noopener noreferrer" className={styles.fileLink}>
            {certificationsList[id]}
          </a>
          <GrTrash
            onClick={() => handleDeleteFile('certifications',0, certId=id)}
            className={styles.fileIconDelete}
          />
        </div>
      );
    });
  };
  
    // FileDropzone component with styling
    const FileDropzone = ({ fileType, onFileUpload }) => {
      const onDrop = useCallback(
        (acceptedFiles) => {
          if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            onFileUpload(file);
          }
        },
        [onFileUpload]
      );
  
      const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
      });
  
      return (
        <div {...getRootProps()} className={styles.dropzone}>
          <input {...getInputProps()} />
          {isDragActive ? <p > + Upload</p> : <p> + Upload </p>}
        </div>
      );
    };
  
  
  
    return (
      <>
        <tr>
          <td>{product.productName}</td>
          <td>{renderFileField('image', product.producentUploadData?.imageURLS)} </td>
          <td>
            {renderFileField(
              'technical-specification',
              product.producentUploadData?.tekniskSpecifikationURL
            )}
          </td>
          <td>
            {renderFileField(
              'BrugsBlad',
              product.producentUploadData?.producentBladURL
            )}
          </td>
          <td>
            <button
              className={styles.certificationButton}
              onClick={() => setShowModal(true)}
            >
              Select Certifications
            </button>
            {renderCertifications()}
          </td>
          <td>{renderFileField('other', product.producentUploadData?.otherURLS)}</td>
        </tr>
        {showModal && (
          <CertificationModal
            show={showModal}
            onClose={() => setShowModal(false)}
            onSave={(selectedCerts, certFiles) => {
              // Update the certifications in the files state
              handleFileChange(certFiles, 'certifications');
            }}
            selectedCertifications={files['certifications']}
            certificationFiles={files['certifications']}
          />
        )}
      </>
    );
  };
  
  
  
  
  
  export default ProductRow;
  
  
  
  
  
  