import React from 'react';
import DetailBox from './DetailBox';
import Loader from '../../components/Utils/Loader';

const MemoizedDetailBoxes = React.memo(({ isLoading, products, Unit, category }) => {
  const renderDetailBoxes = () => {
    if (isLoading) {
      return <div><Loader sizex={200} colors='Green'/> </div>;
    }

    return products.map((product) => {
      const billede_id = product.producentUploadData && Array.isArray(product.producentUploadData.imageURLS) && product.producentUploadData.imageURLS.length > 0
      ? product.producentUploadData.imageURLS[0].url 
      : null;

      return (
        <DetailBox
          key={`${product.productName}-${product.subCategory}`}  
          itemId={product.id}
          data={product}
          category={category}
          SubCategoryAndId={product.subCategory}
          billede_id={billede_id}
          Unit={Unit}
        />
      );
    });
  };

  return (
    <div>
      {renderDetailBoxes()}
    </div>
  );
});

export default MemoizedDetailBoxes;
