import React, { useState, useEffect, useRef } from 'react';
import { postNewProject } from '../../components/APIS/postGetProject';
import './createproject.css';

export const CreateProjectModal = ({onProjectCreated,onClose}) => {
    const modalContentRef = useRef(null);
    const [newProjectName, setNewProjectName] = useState('');

    const handleProjectNameChange = (e) => {
        setNewProjectName(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

      await postNewProject(newProjectName);
      onProjectCreated();
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
         
            if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
              onClose();
            }
    
          
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    
      }, [onClose]);

    return (
        <>
            
            <div className="modalOverlay">
          <div className="modalContent" ref={modalContentRef}>
            <h2 className="modalHeader">Create New Project</h2>
            <form onSubmit={handleSubmit}>
              <label className="modalLabel">Project Name</label>
              <input type="text" value={newProjectName} onChange={handleProjectNameChange}  />
              <button type="submit" className="modalButton">Add New Project</button>
            </form>
          </div>
        </div>
            
        </>
    );
};
