import React, { createContext, useState, useContext } from 'react';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState(JSON.parse(localStorage.getItem('selectedProjects')) || []);
  const [userType, setUserType] = useState({});

  


  return (
    <GlobalContext.Provider value={{ selectedProducts, setSelectedProducts, selectedProjects, setSelectedProjects,userType, setUserType }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(GlobalContext);
}

export const baseUrl = process.env.REACT_APP_API_URL;

