import React, { useEffect, useState } from 'react';
import styles from './searchEngine.module.css';
import { fetchEPDid, claimEPDid, getClaimedEPD, unClaimEPDid } from '../../../components/APIS/uploadAPI';
import { MdDeleteForever } from 'react-icons/md';
import { ImCheckboxChecked, ImCheckboxUnchecked, ImCheckmark } from 'react-icons/im';
import { AiOutlineFilePdf } from 'react-icons/ai';

function SearchEngineID() {
  const [EPDID, setEPDID] = useState('');
  const [EPDDATA, setEPDDATA] = useState([]);
  const [handle, setHandle] = useState(false);
  const [Accepted, setAccepted] = useState([]);
  const [Claimed, setClaimed] = useState([]);
  const [Tol, setTol] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [expiredProducts, setExpiredProducts] = useState([]);

  const handleInput = (input) => {
    input = input.trim();
    return input.split(',').join('&EPDID=');
  };

  const search = async (event) => {
    event.preventDefault();
    if (EPDID !== '') {
      const data = await fetchEPDid(handleInput(EPDID));
      setEPDDATA(data);
      setHandle(true);
    }
  };

  const prefetchOnLoad = async () => {
    let data = await getClaimedEPD();
    return data;
  };

  useEffect(() => {
    prefetchOnLoad().then((data) => {
      setExpiredProducts(data.expiredProducts);
      setClaimed(data.products);
    });
  }, []);

  const DropdownSearch = ({ data }) => {
    let datafound = data.foundProducts;
    let notFound = data.notFound;
    return (
      <div className={styles.dropdown}>
        {datafound.length > 0 && (
          <>
            <h2>Found Products</h2>
            <ul className={styles.foundProductsList}>
              {datafound.map((item, index) => (
                <li className={styles.foundProductItem} key={index}>
                  <div className={styles.productInfo}>
                    <p className={styles.productName}>{item.name}</p>
                    <p className={styles.producerName}>{item.producerName}</p>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.linkStyle}
                    >
                      <AiOutlineFilePdf style={{ fontSize: '24px', color: 'red' }} />
                      <p className={styles.productLink}>{item.epdid}</p>
                    </a>
                  </div>
                  <p style={{ fontSize: '12px', color: 'var(--Black)' }}>Is this your Product?</p>
                  <button
                    onClick={() => handleAccept(true, item.id, item.epdid)}
                    className={styles.actionButtons}
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => handleAccept(false, item.id, item.epdid)}
                    className={styles.actionButtons}
                  >
                    No
                  </button>
                </li>
              ))}
            </ul>
          </>
        )}
        {notFound.length > 0 && (
          <div className={styles.notFoundProducts}>
            <h2>Not Found Products</h2>
            {notFound.map((item, index) => (
              <p key={index}>{item} Product not found</p>
            ))}
          </div>
        )}
      </div>
    );
  };

  const handleAccept = (yn, id, epdid) => {
    if (yn === true) {
      if (Accepted.find((item) => item.id === id)) {
        alert('Product already accepted.');
      } else {
        setAccepted((prevAccepted) => [...prevAccepted, { id, epdid }]);
      }
    }
    setEPDDATA((prevEPDData) => {
      const updatedFoundProducts = prevEPDData.foundProducts.filter((item) => item.id !== id);
      return { ...prevEPDData, foundProducts: updatedFoundProducts };
    });
  };

  const handleDelete = async (id) => {
    await unClaimEPDid(id);
    setAccepted((prevAccepted) => prevAccepted.filter((item) => item.epdid !== id));
    await getClaimedEPD().then((data) => {
      setExpiredProducts(data.expiredProducts);
      setClaimed(data.products);
    });
  };

  const handleTol = () => {
    setTol(!Tol);
  };
  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const idClaimEPDid = async () => {
    try {
      setAccepted((prevAccepted) => prevAccepted.filter((item) => !Claimed.includes(item.epdid)));

      let idArray = Accepted.map((item) => item.epdid);

      if (idArray.length === 0) {
        alert('Please accept at least one product.');
        return;
      }

      if (!Tol) {
        alert('Please accept the terms and conditions.');
        return;
      }

      await claimEPDid(Tol, idArray);

      setTol(false);

      setAccepted([]);
      await getClaimedEPD().then((data) => {
        setExpiredProducts(data.expiredProducts);
        setClaimed(data.products);
      });
    } catch (error) {
      console.error('There was an error claiming the EPD ID:', error);
      return null;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.searchSection}>
        <h1>Search by EPD ID</h1>
        <p>Search for an EPD by entering the EPD ID.</p>
        <p>Example: EPD-123456 or EPD-123456,EPD-654321 for multiple.</p>

        <form onSubmit={search} className={styles.form}>
          <input
            type="text"
            placeholder="EPD ID"
            value={EPDID}
            onChange={(event) => setEPDID(event.target.value)}
            className={styles.inputField}
          />
          <button type="submit" className={styles.searchButton}>
            <svg
              stroke="var(--White)"
              fill="var(--White)"
              strokeWidth="2"
              viewBox="0 0 24 24"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="none"
                stroke="var(--White)"
                strokeWidth="2"
                d="M15,15 L22,22 L15,15 Z M9.5,17 C13.6421356,17 17,13.6421356 17,9.5 C17,5.35786438 13.6421356,2 9.5,2 C5.35786438,2 2,5.35786438 2,9.5 C2,13.6421356 5.35786438,17 9.5,17 Z"
              ></path>
            </svg>
            Search
          </button>
        </form>
        {handle ? <DropdownSearch data={EPDDATA} k={EPDID} /> : null}
      </div>

      <div className={styles.productListContainer}>
        <h2 className={styles.productListTitle}>Accepted EPD ID</h2>
        <div className={styles.productCount}>
          <p>Antal: {Accepted.length}</p>
        </div>
        <ul className={styles.productList}>
          {Accepted.map((item) => (
            <li className={styles.listItem} key={item.epdid}>
              <div className={styles.listItemContent}>
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.linkStyle}
                >
                  <AiOutlineFilePdf style={{ color: 'red' }} />
                  <p className={styles.linkText}>{item.epdid}</p>
                </a>
              </div>
              <MdDeleteForever
                className={styles.deleteIcon}
                onClick={() => handleDelete(item.epdid)}
                size={20}
              />
            </li>
          ))}
          <p>Claimed products: {Claimed.length}</p>
          {Claimed.map((item) => (
            <li className={styles.listItem} key={item.epdid}>
              <ImCheckmark style={{ color: 'var(--Green)', marginLeft: '5px' }} size={18} />
              <div className={styles.listItemContent}>
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.linkStyle}
                >
                  <AiOutlineFilePdf style={{ color: 'red', marginBottom:"3px" }} />
                  <p className={styles.linkText}>{item.epdid}</p>
                </a>
              </div>
              <MdDeleteForever
                className={styles.deleteIcon}
                onClick={() => handleDelete(item.epdid)}
                size={20}
              />
            </li>
          ))}
        </ul>
        <p>Expired products: {expiredProducts.length}</p>
        <ul className={styles.productList}>
          {expiredProducts.map((item) => (
            <li className={styles.listItem} key={item.epdid}>
              <ImCheckmark style={{ color: 'var(--Green)', marginLeft: '5px' }} size={18} />
              <div className={styles.listItemContent}>
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.linkStyle}
                >
                  <AiOutlineFilePdf style={{ color: 'red' }} />
                  <p className={styles.linkText}>{item.epdid}</p>
                </a>
              </div>
              <MdDeleteForever
                className={styles.deleteIcon}
                onClick={() => handleDelete(item.epdid)}
                size={20}
              />
            </li>
          ))}
        </ul>
        <div>
          <div className={styles.checkboxContainer}>
            {Tol ? (
              <ImCheckboxChecked
                onClick={handleTol}
                style={{ cursor: 'pointer', color: 'var(--Green)' }}
              />
            ) : (
              <ImCheckboxUnchecked
                onClick={handleTol}
                style={{ cursor: 'pointer', color: 'var(--Green)' }}
              />
            )}
            <label className={styles.checkboxLabel}>
              By checking this box, I confirm that I have read and agree to the
              <a href="#" onClick={toggleModal}>
                {' '}
                Terms and Conditions
              </a>
              . I understand that by uploading content to Buildtivity, I grant Buildtivity the right
              to use, modify, and distribute my content, and I accept full responsibility for
              ensuring that my content does not infringe on any third-party rights.
            </label>
            {openModal && (
              <div className={styles.overlay} onClick={toggleModal}>
                <div className={styles.modal}>
                  <h2>Terms and Conditions</h2>
                  <div className={styles.modalContent}>
                    <p>
                      <strong>1. Ownership of Uploaded Content:</strong>
                    </p>
                    <p>
                      By uploading content (including but not limited to images, videos, text,
                      designs, and other materials) to Buildtivity, you affirm that you are the
                      rightful owner of the content or have obtained all necessary rights and
                      permissions to upload and share the content. You also confirm that your
                      content does not infringe on the intellectual property rights, privacy, or any
                      other rights of third parties.
                    </p>

                    <p>
                      <strong>2. Grant of Rights:</strong>
                    </p>
                    <p>
                      By uploading content to Buildtivity, you grant Buildtivity and its affiliates
                      an irrevocable, perpetual, worldwide, royalty-free, and non-exclusive license
                      to use, reproduce, modify, adapt, publish, translate, distribute, publicly
                      perform, and display the content in any media or medium, or any form, format,
                      or forum now known or hereafter developed. This includes the right to use your
                      content for commercial, promotional, and marketing purposes without further
                      notice or compensation to you.
                    </p>

                    <p>
                      <strong>3. Responsibility and Liability:</strong>
                    </p>
                    <p>
                      You are solely responsible for the content you upload to Buildtivity and any
                      consequences arising from your use of the content. Buildtivity does not endorse
                      any content uploaded by users and is not liable for any legal consequences that
                      may arise from your content. You agree to indemnify and hold Buildtivity
                      harmless from any claims, damages, liabilities, and expenses (including legal
                      fees) arising out of your content or your breach of these terms.
                    </p>

                    <p>
                      <strong>4. Content Removal:</strong>
                    </p>
                    <p>
                      Buildtivity reserves the right to remove or disable access to any content at
                      its sole discretion for any reason, including if the content is deemed to
                      violate these Terms and Conditions or is otherwise objectionable.
                    </p>

                    <p>
                      <strong>5. Acceptance of Terms:</strong>
                    </p>
                    <p>
                      By uploading content to Buildtivity, you acknowledge that you have read,
                      understood, and agree to be bound by these Terms and Conditions. If you do not
                      agree to these terms, please refrain from uploading any content to the
                      platform.
                    </p>
                  </div>
                  <button onClick={toggleModal} className={styles.closeButton}>
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
          <button onClick={idClaimEPDid} className={styles.acceptButton}>
            Godkend
          </button>
        </div>
      </div>
    </div>
  );
}

export default SearchEngineID;
