import { useGlobalContext,baseUrl } from "../GlobalContext";
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';


export const ProtectedRoute = ({ element: Component, pAuthenticate = false , ...rest }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [pAuth, setPAuth] = useState(null);
    const { userType, setUserType } = useGlobalContext();

    useEffect(() => {
      const verifyAuth = async () => {
        try {
          const response = await fetch(`${baseUrl}/User`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
            }
          });

          if(response.status === 401) {
            localStorage.removeItem('sessionToken');
            setUserType({});

          }
          const data = await response.json();

          
          setIsAuthenticated(data.canUsePlatform)
          setPAuth(data.canEditProducerData);
          

        } catch (error) {
          console.error(`Error fetching user auth status:`, error);
          setIsAuthenticated(false);
        }
      };
      verifyAuth();
    }, []);
    

    if (isAuthenticated === null) {
      return <div>Loading...</div>;
    }

    if (pAuthenticate) {
      return pAuth ? <Component {...rest} /> : <Navigate to="/login" />;
    }
   
      return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
   

  };
  
// export const authProd = ({ element: Component, ...rest }) => {  
//     const [isAuthenticated, setIsAuthenticated] = useState(null);
  
//     useEffect(() => {
//       const verifyAuth = async () => {
//         try {
//           const response = await fetch(`${baseUrl}/user`, {
//             method: 'GET',
//             headers: {
//               'Content-Type': 'application/json',
//               'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
//             }
//           });
//           data = await response.json();
//           if (data.authRole === 'Producer') {
//             setIsAuthenticated(true);
//           }
//         } catch (error) {
//           console.error(`Error fetching user auth status:`, error);
//           setIsAuthenticated(false);
//         }
//       };
//       verifyAuth();
//     }, []);
  
//     if (isAuthenticated === null) {
//       return <div>Loading...</div>; 
//     }
  
//     return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
//   }
  