import React from 'react';

const RadioButtonsGroup = ({ options, selectedValue, handleRadioChange }) => {
  const tworadiosystem = {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    borderRadius: '15px',
    cursor: 'pointer',
    background: 'var(--White)',
    color: 'var(--Black)',
    margin: '5px',
  };

  const tworadiosystemSelected = {
    ...tworadiosystem,
    background: 'var(--Sand',
    color: "var(--White)",
    
  };

  const radioButtonsContainerStyle = {
    display: 'flex',
    border: '4px solid ',
    borderRadius: '25px',
    borderColor: 'var(--Sand)',
    "justifyContent": 'center',
  };

  return (
    <div style={radioButtonsContainerStyle}>
      {options.map((option, index) => (
        <label key={index} style={selectedValue === option ? tworadiosystemSelected : tworadiosystem}>
          <input type="radio" value={option} checked={selectedValue === option} onChange={handleRadioChange} style={{ display: 'none' }} />
          {option}
        </label>
      ))}
    </div>
  );
};

export default RadioButtonsGroup;
