
import React, { useState, useEffect } from 'react';
import './Signup.css';
import { IoCheckboxOutline } from "react-icons/io5";


const dbName = "ConferenceDB";
const storeName = "Participants";

const initDB = () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, 1);
      request.onerror = (event) => reject('Database error: ' + event.target.error);
      request.onsuccess = (event) => resolve(event.target.result);
      request.onupgradeneeded = (event) => {
        let db = event.target.result;
        let store = db.createObjectStore(storeName, { keyPath: 'id', autoIncrement: true });
       
      };
    });
  };
  

function ConferenceForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [db, setDb] = useState(null);



 // Function to show the download button with the slide-in effect
 
  const deleteDB = (dbName) => {
    const request = indexedDB.deleteDatabase(dbName);
  
    request.onsuccess = () => {
      console.log(`Database '${dbName}' deleted successfully.`);
    };
  
    request.onerror = (e) => {
      console.error(`Error deleting database '${dbName}':`, e.target.error);
    };
  
    request.onblocked = () => {
      console.log(`Delete operation was blocked for database '${dbName}'. Make sure to close all open connections to the database.`);
    };
  };
  
  // Use this function to delete the desired database
  
  // Initialize database on component mount
  React.useEffect(() => {
    initDB().then(dbInstance => setDb(dbInstance));
  }, []);


  const addToDB = (name, email) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([storeName], 'readwrite');
      const store = transaction.objectStore(storeName);
      const addRequest = store.add({ name, email });
  
      addRequest.onsuccess = () => {
        resolve();
      };
  
      addRequest.onerror = () => {
        reject(addRequest.error);
      };
  
      transaction.onerror = () => {
        reject(transaction.error);
      };
    });
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    if (!name.trim() || !email.trim()) {
      console.log('Please fill in all fields.');
      return;
    }
  
    // Open a read transaction
    const transaction = db.transaction([storeName], 'readonly');
    const store = transaction.objectStore(storeName);
    const request = store.getAll();
  
    request.onsuccess = async () => {
      const allParticipants = request.result;
      // Filter the participants to find if the email already exists
      const emailExists = allParticipants.some(participant => participant.email === email);
      if (emailExists) {
        console.log('This email is already registered.');
      } else {
        // No email found, proceed to add a new entry
        await addToDB(name, email);
        setName('');
        setEmail('');
        console.log('Added to database!');
      }
    };
  
    request.onerror = (event) => {
      alert('Error searching for email:', event.target.error);
    };
  };


  const handleDownload = async () => {
    const transaction = db.transaction([storeName], 'readonly');
    const store = transaction.objectStore(storeName);
    const request = store.getAll();
    request.onsuccess = () => {
      const allParticipants = request.result;
      const dataString = allParticipants.map(part => `${part.name}, ${part.email}`).join('\n');
      const blob = new Blob([dataString], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'participants.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
  };

  return (
    <div className='sgnp-ttl'>
      <img style={{color:'var(--White)',width:'500px', height:'500px'}} src='./images/mainpagelogo.svg' alt='Buildtivity'/>
      <h1 style={{color:'var(--White',fontFamily:'GothicB'}}> Registrér her og få tidlig adgang</h1>
      <form style={{display:'flex', justifyContent:'left', alignItems:'center'   }}>
        <div >

        <div className="sgnpgroup">      
          <input className='sgnpinput' type="text" value={name} onChange={(e) => setName(e.target.value)} required />
          <span className="sgnphighlight"></span>
          <span className="sgnpbar"></span>
          <label className='sgnplabel'>Name</label>
        </div>
        
        <div className="sgnpgroup">      
          <input className='sgnpinput' type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          <span className="sgnphighlight"></span>
          <span className="sgnpbar"></span>
          <label className='sgnplabel'>Email</label>
        </div>
        </div>
        
        <button className='sgnp-sub' type="submit" onClick={handleSubmit}>Submit</button>
      </form>
      <button className='sgnp-dl' onClick={handleDownload}>Download</button>
    </div>
  );
}

function Signup() {
 


  return (
    <div className='Signup-container'>
        <ConferenceForm />
    </div>
  );
}

export default Signup;