import React, { useState, useEffect, version } from 'react';
import { fetchAllCategories } from '../../../components/APIS/categoryAPI';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import Loader from '../../../components/Utils/Loader';
import { AiOutlineEdit,AiOutlineFilePdf } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import styles from "../../styles/TableUpload.module.css";
import { MdErrorOutline,MdCheckCircleOutline } from "react-icons/md";


function TableRow({data}) {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    // console.log(data);
    useEffect(() => {
        if (!data || !data.id) {
            setError('Invalid product ID');
            setLoading(false);
            return;
        }
      
    }, [files]);


const FileComponent = ({ fileLink, hoverLabel = "Open file" }) => {
    const certificationsList = {
        1: 'CE',
        2: 'Svanemarke',
        3: 'Cradle to Cradle',
        4: 'Indeklima Mark',
        5: 'FSC',
        6: 'PEFC',
        7: 'EU Ecolabel',
        8: 'Der Blue Engel',
        9: 'Astma Mark',
        10: 'Allergy UK',
    };

    // Check if fileLink is empty, null, or an empty array
    if (!fileLink || (Array.isArray(fileLink) && fileLink.length === 0)) {
        return (
            <div>
                <AiOutlineFilePdf
                    color="grey"
                    style={{ fontSize: "2em" }}
                    title="No file available"
                />
            </div>
        );
    }

    // Normalize fileLinks to handle both arrays of objects (like certifications) and string URLs
    const fileLinks = Array.isArray(fileLink)
        ? fileLink.map(item => {
              if (typeof item === 'string') return { url: item, label: hoverLabel };
              return {
                  url: item.url,
                  label: certificationsList[item.certification] || hoverLabel, // Get label from certificationsList
              };
          })
        : [{ url: fileLink, label: hoverLabel }];

    return (
        <div style={{ display: 'flex', justifyContent: "center" }}>
            {fileLinks.map((file, index) => (
                <div key={index}>
                    <AiOutlineFilePdf
                        color="red"
                        style={{ fontSize: "2em", cursor: "pointer" }}
                        onClick={() => window.open(file.url, '_blank')}
                        title={file.label} // Display the certification label as a tooltip
                        aria-label={file.label}
                        role="button"
                    />
                </div>
            ))}
        </div>
    );
};


// Helper function to extract file name from the URL
const getFileName = (url) => {
    try {
        const urlObj = new URL(url, window.location.origin);
        return decodeURIComponent(urlObj.pathname.substring(urlObj.pathname.lastIndexOf('/') + 1));
    } catch (e) {
        return url;
    }
};



    
//     // Return the red icon with click functionality if file link exists
//     return (
//         <div>
//             <AiOutlineFilePdf
//                 color="red"
//                 style={{ fontSize: "1.5em", cursor: "pointer" }}
//                 onClick={() => window.open(fileLink, '_blank')}
//             />
            
//         </div>
//     );

// };
const CorruptPDF = ({ trfa }) => {
    return (
        <div>
            {!trfa ? (
                <MdCheckCircleOutline  style={{ fontSize: "2em",color:"var(--Green", cursor: "pointer" }} />
            ) : (
                <MdErrorOutline style={{ fontSize: "2em",color:"ORANGE", cursor: "pointer" }}  title="One or more PDFs might be corrupt" />
            )}
        </div>
    );
};

return (
    <tr>
        <td>
            <div style={{display:"flex",flexDirection:"row",gap:"0.5em",justifyContent:"center"}}>

            <NavLink 
                to={`/produkter/correct/${data.id}`} 
                state={{ data, version: false }} 
                className={styles.firstColumn}
            >
                <AiOutlineEdit style={{ fontSize: "2em",color:"var(--Green", cursor: "pointer" }} />
            </NavLink>

            <CorruptPDF trfa={data.corruptPDF} />


            </div>

        </td>
        <td>{data.validTo}</td>
        <td>{data.productName}</td>
        <td>{data.epdid}</td>
        {/* <td>
            {data.rsl.map((rsl, index) => (
                <div key={index}>{rsl.rslValue}</div>
            ))}
        </td> */}
        <td>
            {data.compliance.map((compliance, index) => (
                <div key={index}>{compliance}</div>
            ))}
        </td>
        <td>
            <FileComponent fileLink={data.producentUploadData.tekniskSpecifikationURL} />
        </td>
        <td>
        {
                [...new Set(
                    data.declaredUnits
                        .filter(declaredUnit => declaredUnit.safetyLevel >= 1) // Filter by safetyLevel
                        .map(declaredUnit => declaredUnit.unit) // Map to unit values
                )].map((unit, index) => (
                    <div key={index}>{unit}</div> // Render unique units
                ))
            }
        </td>
        <td >
            {/* Pass certifications with a hover label indicating "Certification" */}
            <FileComponent fileLink={data.producentUploadData.certifications} hoverLabel="Certification" />
        </td>
        <td>
            {/* Pass other URLs if needed, using the default label */}
            <FileComponent fileLink={data.producentUploadData.otherURLS} />
        </td>
    </tr>
);
};



export default TableRow;

